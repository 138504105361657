import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import ChatHeaderMenu from './ChatHeaderMenu';
import Tooltip from './Tooltip';
import Editable from 'assets/icons_refactor/BlendTalk/editable.svg';
import Help from 'assets/icons_refactor/Client/hint.svg';
import useMobile from 'hooks/useMobile';
import {useStores} from 'hooks/useStores';
import {boldWeight} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

interface ChatListHeaderProps {
  onOpenNewGroup: () => void;
}

const {
  CHAT_LIST_TITLE,
  HELP_TITLE,
  USER_TYPE: {TRANSLATOR},
  DATA_QA_CHAT_PAGE_TITLE,
  DATA_QA_CHAT_ADD_CHAT_ICON,
} = Consts;

const ChatListHeader: FC<ChatListHeaderProps> = observer(({onOpenNewGroup}) => {
  const {isMobile, isTablet} = useMobile();
  const {
    blendTalkStore: {currentUser},
  } = useStores();

  const isTranslator = useMemo(() => currentUser?.type === TRANSLATOR, [currentUser?.type]);
  const isShowIcon = useMemo(() => isMobile || isTablet, [isMobile, isTablet]);

  return (
    <Wrapper>
      <Title data-qa-auto={DATA_QA_CHAT_PAGE_TITLE}>{CHAT_LIST_TITLE}</Title>
      <Tooltip title={HELP_TITLE}>
        <HelpIcon icon={Help} boxH={16} boxW={16} />
      </Tooltip>
      {isShowIcon ? (
        <Icon icon={Editable} onClick={onOpenNewGroup} data-qa-auto={DATA_QA_CHAT_ADD_CHAT_ICON} boxH={20} boxW={20} />
      ) : (
        <Container>{!isTranslator && <ChatHeaderMenu onOpenNewGroup={onOpenNewGroup} />}</Container>
      )}
    </Wrapper>
  );
});

const Title = styled.div`
  font-size: 20px;
  ${boldWeight};
  line-height: 20px;
  margin-left: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding-bottom: 10px;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey030};
    box-sizing: border-box;
  }
`;

const HelpIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey080};
  width: 16px;
  height: 16px;
`;

const Container = styled.div`
  margin: 0 16px 0 auto;
`;

const Icon = styled(BaseIcon)`
  margin: 0 16px 0 auto;
  cursor: pointer;
`;

export default ChatListHeader;
