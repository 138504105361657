import {UserData} from 'pages/Wizard/types';
import {GtmEventInterface, ITrackingState} from './tracking.types';

const useTrackingState = (userData: UserData | null): ITrackingState => {
  const gtmTrack = (props: GtmEventInterface): void => {
    const windowObject = window as any;
    if (!windowObject.dataLayer) {
      windowObject.dataLayer = [];
    }
    const dataLayer = windowObject.dataLayer;
    const vtc = document.cookie.match('_vtc=([^;]*)');

    const eventData = {
      user_id: userData?.id,
      vtc_key: vtc && vtc[1],
      ...props,
    };

    dataLayer.push(eventData);
  };

  return {
    gtmTrack,
  };
};

export default useTrackingState;
