import React, {FC, useMemo} from 'react';
import styled from 'styled-components';
import BaseLink from 'components/BaseComponents/BaseLink';
import BaseImage from 'components/BaseComponents/BaseImage';
import PageLayout from 'components/GeneralComponents/PageLayout';
import useConfig from 'hooks/useConfig';
import NotFoundImg from 'assets/images/404/not-found.webp';
import NotFoundMobileImg from 'assets/images/404/not-found-mobile.webp';
import {boldWeight, font_large, font_header_medium} from 'theme/fonts';
import Consts from './NotFoundConsts';

const {TITLE, SUB_TITLE, DESCRIPTION, SUB_DESCRIPTION, SUPPORT, SUPPORT_EMAIL, HINT} = Consts;

interface NotFoundViewProps {
  text?: string;
}

const NotFoundView: FC<NotFoundViewProps> = ({text}) => {
  const {
    config: {blendDomainURL, appDomainURL},
  } = useConfig();

  const links = useMemo(
    () => [
      {
        name: 'Homepage',
        link: `https://${appDomainURL}/`,
      },
      {
        name: 'Blog',
        link: `https://${blendDomainURL}/blog/`,
      },
      {
        name: 'Contact Us',
        link: `https://${blendDomainURL}/contact-us/`,
      },
      {
        name: 'Translate Now',
        link: `https://${appDomainURL}/wizard`,
      },
    ],
    [appDomainURL, blendDomainURL]
  );

  return (
    <PageLayout>
      <Wrapper>
        <Info>
          <Title>{TITLE}</Title>
          <SubTitle>{SUB_TITLE}</SubTitle>
          <Description>
            {text ? (
              text
            ) : (
              <>
                {DESCRIPTION}
                <div>{SUB_DESCRIPTION}</div>
              </>
            )}
          </Description>
        </Info>
        <MobileImage src={NotFoundMobileImg} alt="dog" />
        <Support>
          {SUPPORT} <BaseLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</BaseLink>
        </Support>
        <Hint>{HINT}</Hint>
        <LinksBlock>
          {links.map(({name, link}) => (
            <Link key={name} href={link}>
              {name}
            </Link>
          ))}
        </LinksBlock>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.grey000};
`;

const Info = styled.div`
  height: 400px;
  width: 780px;
  background: no-repeat center/100% url(${NotFoundImg});
  position: relative;

  @media only screen and (max-width: 1024px) {
    height: 370px;
    width: 720px;
  }

  @media only screen and (max-width: 768px) {
    background: none;
    height: fit-content;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 36px;
    text-align: center;
  }
`;

const SubTitle = styled.h2`
  ${font_header_medium};
  font-weight: 800;
  margin-bottom: 14px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`;

const Description = styled.div`
  ${font_large};
  max-width: 60%;

  @media only screen and (max-width: 768px) {
    text-align: center;
    max-width: 50%;
    margin: auto;
  }

  @media only screen and (max-width: 390px) {
    max-width: 40%;
  }
`;

const Support = styled.div`
  ${font_large};
  width: 100%;
  margin-top: 36px;
  text-align: center;
`;

const MobileImage = styled(BaseImage)`
  margin-top: 36px;
  height: 275px;
  width: 360px;

  @media (min-width: 769px) {
    display: none;
  }

  @media only screen and (max-width: 390px) {
    height: 240px;
    width: 315px;
  }

  @media only screen and (max-width: 320px) {
    height: 220px;
    width: 290px;
  }
`;

const Hint = styled.div`
  font-size: 20px;
  ${boldWeight};
  margin-top: 60px;
  text-align: center;

  @media only screen and (max-width: 390px) {
    ${font_large};
    ${boldWeight};
  }
`;

const LinksBlock = styled.div`
  margin: 16px 0;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Link = styled(BaseLink)`
  ${font_large};
  color: ${({theme}) => theme.colors.blue100};
  border: 1px solid ${({theme}) => theme.colors.blue100};
  border-radius: 32px;
  padding: 15px;
  min-width: 182px;
  width: 100%;
  margin: 8px;
  display: flex;
  justify-content: center;
  font-weight: 500;

  @media only screen and ${({theme}) => theme.breakpoints.maxMd} {
    min-width: 170px;
  }

  &:hover {
    text-decoration: none;
    background: ${({theme}) => theme.colors.grey030};
  }
`;

export default NotFoundView;
