import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import useTracking from 'context/Tracking/useTracking';
import {observer} from 'mobx-react';
import BaseCheckbox from 'components/BaseComponents/BaseCheckbox';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import BaseSimpleInput from 'components/BaseComponents/BaseSimpleInput';
import NewGroupUser from './NewGroupUser';
import NewMyTeamGroupHeader from './NewMyTeamGroupHeader';
import SelectedGroupUsers from './SelectedGroupUsers';
import {LanguagePair} from 'store/pages/blendTalkStore';
import Search from 'assets/icons_refactor/Client/search.svg';
import {useUser} from 'context/UserData';
import useMobile from 'hooks/useMobile';
import {useStores} from 'hooks/useStores';
import {GTMEvents} from 'utils/globalEvents';
import {boldWeight, font_large, font_medium} from 'theme/fonts';
import ErrorText from './ErrorText';
import Consts from '../BlendTalkConsts';

const {
  CHAT_BODY: {NEW_CHAT, REVIEW_SELECTED_GROUP, SELECTED_CHAT},
  SELECT_ALL,
  SEARCH_PLACEHOLDER,
  NEXT,
  BACK,
  SELECT_USERS_TITLE,
  ADD_NEW_USER_TO_SELECTED_CHAT,
  NEW_CHAT_TITLE,
  SEARCH_PLACEHOLDER_MD,
  RESPONSE_ERROR_TEXT,
  DATA_QA_CHAT_USER_TO_ADD_TO_CHAT,
  DATA_QA_CHAT_SELECT_ALL_USERS_BUTTON,
} = Consts;

const {click_chat_add_new_member} = GTMEvents;

interface NewMyTeamGroupProps {
  onChangeBodyContent: (value: string) => void;
  handleSelectId: (value: string[] | ((value: string[]) => string[])) => void;
  selectedIds: string[];
  isNewMembers?: boolean;
  token: string | null;
}

const NewMyTeamGroup: FC<NewMyTeamGroupProps> = observer(
  ({onChangeBodyContent, selectedIds, handleSelectId, isNewMembers, token}) => {
    const [search, setSearch] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);
    const {isMobile, isTablet} = useMobile();
    const {userData} = useUser();
    const {gtmTrack} = useTracking();

    const {
      blendTalkStore: {loading, contacts, addNewMemberToChat, error, showChatNotifications},
    } = useStores();

    useEffect(() => {
      error.contacts && showChatNotifications(RESPONSE_ERROR_TEXT);
    }, [error.contacts, showChatNotifications]);

    const filteredUsers = useMemo(() => {
      if (!search) {
        return contacts;
      } else {
        return contacts.filter(({user: {name, languagePairs}}) => {
          const lowerCaseSearch = search.toLowerCase();

          const matchesLanguagePair = (languagePair: LanguagePair) => {
            const sourceLang = languagePair?.sourceLanguage?.toLowerCase();
            const targetLang = languagePair?.targetLanguage?.toLowerCase();
            return sourceLang?.includes(lowerCaseSearch) || targetLang?.includes(lowerCaseSearch);
          };

          return name.toLowerCase().includes(lowerCaseSearch) || languagePairs.some(matchesLanguagePair);
        });
      }
    }, [search, contacts]);

    useEffect(() => {
      setChecked(filteredUsers.length === selectedIds.length);
    }, [filteredUsers.length, selectedIds.length]);

    const toggleAddUser = useCallback(
      (id: string, isChecked: boolean) =>
        handleSelectId((ids) => (isChecked ? [...ids, id] : ids.filter((userId) => userId !== id))),
      [handleSelectId]
    );

    const handleSelectAll = useCallback(() => {
      setChecked((prev) => !prev);

      handleSelectId(checked ? [] : filteredUsers.map(({user: {id}}) => id));
    }, [checked, filteredUsers, handleSelectId]);

    const handleDeleteUser = useCallback(
      (id: string) => {
        handleSelectId((prev) => prev.filter((prevId) => prevId !== id));
        selectedIds.length === 1 && setSearch('');
      },
      [handleSelectId, selectedIds.length]
    );

    const handleNext = useCallback(
      () => selectedIds.length && onChangeBodyContent(isNewMembers ? SELECTED_CHAT : REVIEW_SELECTED_GROUP),
      [onChangeBodyContent, isNewMembers, selectedIds.length]
    );

    const handlePrev = useCallback(
      () => onChangeBodyContent(isNewMembers ? SELECTED_CHAT : NEW_CHAT),
      [isNewMembers, onChangeBodyContent]
    );

    const handleAddNewMember = useCallback(
      (id: string) => {
        if (isNewMembers && token) {
          addNewMemberToChat(id, token);
          userData?.type && gtmTrack({event: click_chat_add_new_member, user_role: userData?.type});
        }
      },
      [addNewMemberToChat, isNewMembers, token, userData?.type]
    );

    const groupHeader = useMemo(
      () => (isMobile || isTablet ? NEW_CHAT_TITLE : SELECT_USERS_TITLE),
      [isMobile, isTablet]
    );

    const inputPlaceholder = useMemo(
      () => (isMobile || isTablet ? SEARCH_PLACEHOLDER_MD : SEARCH_PLACEHOLDER),
      [isMobile, isTablet]
    );

    return (
      <Wrapper>
        <NewMyTeamGroupHeader
          onNext={handleNext}
          onPrev={handlePrev}
          isReadyNext={selectedIds.length ? 1 : 0}
          nextText={NEXT}
          prevText={BACK}
          title={isNewMembers ? ADD_NEW_USER_TO_SELECTED_CHAT : groupHeader}
          isHideNextButton={isNewMembers}
        />
        <Container>
          <SearchUsers>
            <BaseIcon icon={Search} />
            <Input value={search} onChange={({target: {value}}) => setSearch(value)} placeholder={inputPlaceholder} />
          </SearchUsers>
          <SelectedGroupUsers ids={selectedIds} users={contacts} onDelete={handleDeleteUser} searchWord={search} />
          {!isNewMembers && !!contacts.length && (
            <SelectAllUsersContainer>
              <SelectAllTitle>{SELECT_USERS_TITLE}</SelectAllTitle>
              <SelectAllUsers onClick={handleSelectAll} data-qa-auto={DATA_QA_CHAT_SELECT_ALL_USERS_BUTTON}>
                <BaseCheckbox checked={checked} />
                <Text>{SELECT_ALL}</Text>
              </SelectAllUsers>
            </SelectAllUsersContainer>
          )}
          <Users data-qa-auto={DATA_QA_CHAT_USER_TO_ADD_TO_CHAT}>
            {filteredUsers.map(({user}) => (
              <NewGroupUser
                onChange={toggleAddUser}
                user={user}
                selectedIds={selectedIds}
                key={user.id}
                isNewMember={isNewMembers}
                onAdd={handleAddNewMember}
              />
            ))}

            {(loading.contacts || loading.members) && <Loader />}
            {error.contacts && <ErrorText text={error.contacts} />}
          </Users>
        </Container>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  padding: 0 24px 0 16px;
  box-sizing: border-box;
  height: calc(100vh - 200px);

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0;
    height: calc(100vh - 145px);
  }
`;

const SelectAllUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({theme}) => theme.colors.grey030};
  column-gap: 10px;
  margin: 18px 0;
  cursor: pointer;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin: 14px 0 8px 0;
  }
`;

const Users = styled.div`
  width: 100%;
  height: calc(100% - 190px);
  overflow-y: auto;
  position: relative;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    @media screen and (orientation: landscape) {
      min-height: 200px;
    }
  }
`;

const Input = styled(BaseSimpleInput)`
  .MuiInputBase-input {
    height: 46px;
    box-sizing: border-box;
    border: none;

    &:focus,
    &:hover {
      border: none;
    }
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    .MuiInputBase-input {
      height: 35px;
      background-color: ${({theme}) => theme.colors.grey010};
    }
  }
`;

const SearchUsers = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.grey000};
  border: 1px solid ${({theme}) => theme.colors.grey030};
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 16px;
  box-sizing: border-box;
  margin-top: 27px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    background-color: ${({theme}) => theme.colors.grey010};
    border: 1px solid ${({theme}) => theme.colors.grey020};
    height: 38px;
    margin: 14px 16px 0 16px;
    width: calc(100% - 32px);
    border-radius: 4px;
  }
`;

const Text = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey100};
  user-select: none;
`;

const Loader = styled(BaseLoader)`
  margin: 0 auto;
  display: block;
`;

const SelectAllUsersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    padding: 0 16px;
  }
`;

const SelectAllTitle = styled.div`
  display: none;
  ${font_large};
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey100};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: block;
    margin-right: auto;
  }
`;

export default NewMyTeamGroup;
