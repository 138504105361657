import React, {FC, MouseEvent, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useSearchParams} from 'react-router-dom';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import Arrow from 'assets/icons_refactor/Common/small-arrow.svg';
import {useStores} from 'hooks/useStores';
import {font_large} from 'theme/fonts';
import {getChatMessageData} from 'api/blendTalk';
import Consts from '../BlendTalkConsts';
import {Chat} from 'store/pages/blendTalkStore';

const {UNREAD_MESSAGE, MESSAGE_TYPE} = Consts;

interface UnreadMessagesProps {
  parentHeight: number;
}

const UnreadMessages: FC<UnreadMessagesProps> = observer(({parentHeight}) => {
  const {
    blendTalkStore: {
      chatId,
      chatList,
      notifications,
      token,
      messages,
      isChatBottomDistanceExceeded,
      setChatBottomDistanceExceed,
      readChatById,
      setSelectedMessageId,
      getChatMessages,
    },
  } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentChat: Chat | null = useMemo(
    () => chatList.find((chat) => chat.chatId === chatId) || null,
    [chatId, chatList]
  );
  const unreadMessages = useMemo(
    () =>
      currentChat && notifications[currentChat.chatId]?.filter((notification) => notification.type === MESSAGE_TYPE),
    [notifications, currentChat]
  );
  const firstUnreadMessageId = useMemo(() => !!unreadMessages?.length && unreadMessages[0].id, [unreadMessages]);

  const unReadMessagesCount = useMemo(() => unreadMessages?.length, [unreadMessages]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (firstUnreadMessageId) {
        const searchParamsUrl = new URLSearchParams(searchParams);
        searchParamsUrl.delete('message');
        searchParamsUrl.append('message', firstUnreadMessageId);

        setSearchParams(searchParamsUrl.toString());

        setSelectedMessageId(firstUnreadMessageId);
        setChatBottomDistanceExceed(false);
        token && chatId && readChatById(chatId, token);

        const isIncludes = messages.some((m) => m.id === firstUnreadMessageId);

        if (!isIncludes && token && chatId) {
          getChatMessageData(firstUnreadMessageId, token).then((response) => {
            response.pageNumber && getChatMessages(response.pageNumber, token, undefined, chatId);
          });
        }
      }
    },
    [
      chatId,
      firstUnreadMessageId,
      messages,
      token,
      readChatById,
      getChatMessages,
      setSelectedMessageId,
      setChatBottomDistanceExceed,
    ]
  );

  return isChatBottomDistanceExceeded && !!unReadMessagesCount ? (
    <Wrapper onClick={handleClick} parentHeight={parentHeight}>
      <ArrowIcon icon={Arrow} boxH={20} boxW={20} />
      <Text>{`${unReadMessagesCount} ${UNREAD_MESSAGE}${
        unReadMessagesCount && unReadMessagesCount > 1 ? 's' : ''
      }`}</Text>
    </Wrapper>
  ) : null;
});

const Wrapper = styled.div<{parentHeight: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  column-gap: 4px;
  border-radius: 37px;
  background-color: ${({theme}) => theme.colors.grey100};
  position: absolute;
  bottom: ${({parentHeight}) => 20 + parentHeight}px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;

const Text = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey000};
`;

const ArrowIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  fill: ${({theme}) => theme.colors.grey000};
`;

export default UnreadMessages;
