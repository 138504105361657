import core from './core';
import queryString from 'query-string';
import {ConvertFormField, ConvertInvoice, ConvertTableItem} from '../pages/Backoffice/components/Convert/ConvertUtils';
import {handleAddAuthToken} from '../pages/BlendTalk/BlendTalkUtils';

export interface ConvertResultMeta {
  total: number;
  currentPage: number;
  limit: number;
}

export interface ConvertTableResult {
  items: ConvertTableItem[];
  meta: ConvertResultMeta;
}

export const getConvertData = (params: ConvertFormField, token: string): Promise<ConvertTableResult> => {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value && value !== 'all'));

  const queryStringParams = queryString.stringify({...filteredParams, perPage: params.perPage});
  return core
    .get(`/api/2/admin/convert/requests?${queryStringParams}`, handleAddAuthToken(token))
    .then(({data}) => data.results);
};

export const fetchConvertCSV = (params: ConvertFormField) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value && value !== 'all' && key !== 'perPage')
  );

  const queryStringParams = queryString.stringify(filteredParams);
  return core({
    url: `/api/2/admin/convert/requests/downloads/csv?${queryStringParams}`,
    method: 'get',
    responseType: 'blob',
  }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    const filename = response.headers['content-disposition'].split('filename=')[1] || 'file.csv';
    link.href = href;

    link.setAttribute('download', filename);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const updateStatuses = (ids: number[], status: string, token: string) => {
  const formData = new FormData();

  ids.forEach((id) => {
    formData.append(`req[${id}]`, String(id));
    formData.append('reqs', String(1));
  });
  formData.append('status', status);

  formData.append('_', '');

  return core
    .post(`/api/2/admin/creditsConvert/updateConversion?CSRFToken=${token}`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(({data}) => data);
};

export const fetchInvoiceData = (id: number, token: string): Promise<ConvertInvoice> =>
  core.get(`/api/2/account/convert/invoice/${id}`, handleAddAuthToken(token)).then(({data}) => data.results);

export const payoutUpdate = (ids: number[], token: string) => {
  const formData = new FormData();
  const reqs: {[key: string]: string} = {};

  formData.append('CSRFToken', token);

  ids.forEach((id) => (reqs[id] = '1'));
  formData.append('reqs', JSON.stringify(reqs));

  return core
    .post(`/adminService/payout_update`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authorization: `Bearer ${token}`,
      },
    })
    .then(({data}) => data.results);
};
