import React, {FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import useTracking from 'context/Tracking/useTracking';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseMenu from 'components/BaseComponents/BaseMenu';
import SystemMessage from './SystemMessage';
import Tooltip from './Tooltip';
import LockFull from 'assets/icons_refactor/Wizard/lock-full.svg';
import PinFull from 'assets/icons_refactor/BlendTalk/pin-full.svg';
import More from 'assets/icons_refactor/Client/more-actions-vertical.svg';
import {chatCellOptions, ChatType, getChatWrapperBgColor, getSinceDate} from '../BlendTalkUtils';
import {GTMEvents} from 'utils/globalEvents';
import {boldWeight, mediumWeight, font_large, font_small, font_medium, regularWeight} from 'theme/fonts';
import {Chat} from 'store/pages/blendTalkStore';
import {useUser} from 'context/UserData';
import useSwipe from 'hooks/useSwipe';
import {useStores} from 'hooks/useStores';
import Consts from '../BlendTalkConsts';

const {
  REACTION,
  PIN,
  MESSAGE_ACTION_TEXT: {UNPIN},
  USER_TYPE: {TRANSLATOR},
  DEFAULT_CHAT_TITLE,
  DEFAULT_CHAT_TITLE_FROM_BE,
  SYSTEM_MESSAGE: {DELETED_FILE},
  DATA_QA_CHAT_CELL_TITLE,
  DATA_QA_CHAT_CREATION_DATE,
  DATA_QA_CHAT_AUTHOR,
  DATA_QA_CHAT_LAST_MESSAGE,
  DATA_QA_CHAT_CELL,
  DATA_QA_CHAT_PINNED_ICON,
  SYSTEM,
} = Consts;

const {click_chat_list_unread_messages, click_pin_chat, click_un_pin_chat} = GTMEvents;

const {DEFAULT} = ChatType;

interface ChatCellProps {
  item: Chat;
  selectedId: string | null;
  onSelect: (id: string, withoutNotification?: boolean, onChatItemClick?: boolean) => void;
  selectedIdForInitScroll: string | null;
}

const ChatCell: FC<ChatCellProps> = observer(
  ({
    item: {chatId, title, lastMessage, deletedAt, pinnedAt, type, createdAt, owner},
    onSelect,
    selectedId,
    selectedIdForInitScroll,
  }) => {
    const {
      blendTalkStore: {updateChatById, token, currentUser, notifications},
    } = useStores();
    const {userData} = useUser();
    const {gtmTrack} = useTracking();

    const [offsetX, setOffsetX] = useState<number>(0);
    const chatRef = useRef<HTMLDivElement | null>(null);
    const isSelected = useMemo(() => chatId === selectedId, [chatId, selectedId]);
    const selected = useMemo(() => (isSelected ? 1 : 0), [isSelected]);
    const isSelectedForScroll = useMemo(() => chatId === selectedIdForInitScroll, [chatId, selectedIdForInitScroll]);
    const scrolled = useRef<boolean>(false);

    const unReadMessages = useMemo(() => notifications[chatId]?.length, [notifications, chatId]);

    const handleSelectChat = useCallback(() => {
      offsetX < 0 ? setOffsetX(0) : onSelect(chatId, undefined, true);
      !!unReadMessages &&
        userData?.type &&
        gtmTrack({event: click_chat_list_unread_messages, user_role: userData?.type});
    }, [chatId, offsetX, onSelect, unReadMessages, userData?.type]);

    const handleScroll = useCallback(() => {
      const chatElement = chatRef?.current;
      const elementRect = chatElement?.getBoundingClientRect();

      if (elementRect) {
        if (elementRect?.bottom + elementRect?.height >= window.innerHeight) {
          chatElement?.scrollIntoView({block: 'end'});
        }
      }
    }, [chatRef]);

    useEffect(() => {
      if (!scrolled.current && isSelectedForScroll) {
        handleScroll();
        scrolled.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const togglePin = useCallback(
      (e: any) => {
        e?.stopPropagation();
        token && updateChatById(chatId, {isPinned: !pinnedAt}, token);
        userData?.type && gtmTrack({event: !pinnedAt ? click_pin_chat : click_un_pin_chat, user_role: userData?.type});
      },
      [token, updateChatById, chatId, pinnedAt, userData?.type]
    );

    const reaction = useMemo(
      () => notifications[chatId]?.filter((notification) => notification.type === REACTION).length,
      [notifications, chatId]
    );

    const unread = useMemo(() => notifications[chatId]?.length || 0, [notifications, chatId]);

    const createdDate = useMemo(
      () => getSinceDate(lastMessage?.createdAt ? lastMessage?.createdAt : createdAt),
      [lastMessage?.createdAt, createdAt]
    );

    const handleSwipeLeft = useCallback(() => {
      setOffsetX((prev) => (prev ? 0 : -75));
    }, []);

    const handleClickSwipedPin = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        setOffsetX(0);
        token && updateChatById(chatId, {isPinned: !pinnedAt}, token);
      },
      [token, updateChatById, chatId, pinnedAt]
    );

    useSwipe(chatRef, handleSwipeLeft);

    const isShowAttachment = useMemo(() => !lastMessage?.text && lastMessage?.attachments?.length, [lastMessage]);

    const attachmentName = useMemo(
      () => !!lastMessage?.attachments?.length && lastMessage?.attachments[0].filename,
      [lastMessage]
    );

    const ownerName = useMemo(
      () => (currentUser?.type === TRANSLATOR && owner?.name ? `(${owner?.name}) ` : ''),
      [currentUser?.type, owner?.name]
    );

    const defaultChatTitle = useMemo(
      () =>
        type === DEFAULT && currentUser?.type === TRANSLATOR && title === DEFAULT_CHAT_TITLE_FROM_BE
          ? DEFAULT_CHAT_TITLE
          : title,
      [type, currentUser, title]
    );

    const chatTitle = useMemo(
      () => (currentUser?.type === TRANSLATOR ? `${ownerName}${defaultChatTitle}` : title),
      [ownerName, currentUser, title, defaultChatTitle]
    );

    const text = useMemo(
      () => (lastMessage?.type === SYSTEM ? <SystemMessage message={lastMessage} isChatCell /> : lastMessage?.text),
      [lastMessage]
    );

    return (
      <Wrapper
        onClick={handleSelectChat}
        defaultType={type === DEFAULT ? 1 : 0}
        bgc={getChatWrapperBgColor(isSelected, type === DEFAULT, unread)}
        ref={chatRef}
        offsetX={offsetX}
        data-qa-auto={DATA_QA_CHAT_CELL}
      >
        {!!deletedAt && <LockIcon icon={LockFull} selected={selected} />}
        <Content>
          <Header>
            <TitleContainer data-qa-auto={DATA_QA_CHAT_CELL_TITLE}>
              <Tooltip title={chatTitle}>
                <Title selected={selected}>{chatTitle}</Title>
              </Tooltip>
              {!!unReadMessages && <Messages selected={selected}>{unReadMessages}</Messages>}
              {!!reaction && <HeartIcon>{'🤍'}</HeartIcon>}
            </TitleContainer>
            <Created selected={selected} data-qa-auto={DATA_QA_CHAT_CREATION_DATE}>
              {createdDate}
            </Created>
            {((type !== DEFAULT && !deletedAt) || currentUser?.type === TRANSLATOR) && (
              <BaseMenu options={chatCellOptions(!!pinnedAt, togglePin)}>
                <MoreIcon icon={More} selected={selected} />
              </BaseMenu>
            )}
          </Header>
          {lastMessage?.type !== SYSTEM && (
            <Author selected={selected} data-qa-auto={DATA_QA_CHAT_AUTHOR}>
              {lastMessage?.author?.name}
            </Author>
          )}
          <Body>
            {!isShowAttachment && (
              <Message
                selected={selected}
                lastdeleted={lastMessage?.deletedAt ? 1 : 0}
                deleted={deletedAt ? 1 : 0}
                data-qa-auto={DATA_QA_CHAT_LAST_MESSAGE}
                system={lastMessage?.type === SYSTEM ? 1 : 0}
              >
                {lastMessage?.deletedAt ? DELETED_FILE : text}
              </Message>
            )}
            {!!isShowAttachment && <Attachment>{attachmentName}</Attachment>}
            {!!pinnedAt && <PinIcon icon={PinFull} selected={selected} data-qa-auto={DATA_QA_CHAT_PINNED_ICON} />}
          </Body>
        </Content>
        {((type !== DEFAULT && offsetX < 0) || currentUser?.type === TRANSLATOR) && (
          <SwapPinContainer onClick={handleClickSwipedPin}>
            <PinIconSwap icon={PinFull} boxH={20} boxW={20} />
            {pinnedAt ? UNPIN : PIN}
          </SwapPinContainer>
        )}
      </Wrapper>
    );
  }
);

const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Created = styled.div<{selected: number}>`
  ${font_medium};
  color: ${({
    theme: {
      colors: {grey080, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey080)};
  flex-shrink: 0;
`;

const MoreIcon = styled(BaseIcon)<{selected: number}>`
  display: none;
  margin-left: auto;
  cursor: pointer;
  fill: ${({
    theme: {
      colors: {grey080, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey080)};
`;

const PinIcon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    theme: {
      colors: {grey090, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey090)};
`;

const LockIcon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    theme: {
      colors: {grey100, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey100)};
  flex-shrink: 0;
`;

const Messages = styled.div<{selected: number}>`
  ${font_small};
  color: ${({
    theme: {
      colors: {grey000, blue100},
    },
    selected,
  }) => (selected ? blue100 : grey000)};
  line-height: 1;
  background-color: ${({
    theme: {
      colors: {blue100, grey000},
    },
    selected,
  }) => (selected ? grey000 : blue100)};
  border-radius: 100px;
  padding: 2px 6px;
  max-width: 48px;
  flex-shrink: 0;
  ${overflowStyles};
`;

const Title = styled.div<{selected: number}>`
  ${font_large};
  ${mediumWeight};
  max-width: 220px;
  ${overflowStyles};
  color: ${({
    theme: {
      colors: {grey100, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey100)};
`;

const Author = styled.div<{selected: number}>`
  ${font_large};
  color: ${({
    theme: {
      colors: {grey100, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey100)};
  width: 100%;
  ${overflowStyles};
  margin-bottom: 4px;
  flex-shrink: 0;
`;

const Message = styled.div<{selected: number; lastdeleted: number; deleted: number; system: number}>`
  ${font_large};
  ${({selected}) => (selected ? boldWeight : regularWeight)};
  font-style: ${({lastdeleted, system}) => (lastdeleted || system ? 'italic' : 'normal')};
  color: ${({
    theme: {
      colors: {grey090, grey000},
    },
    selected,
  }) => (selected ? grey000 : grey090)};
  width: ${({deleted}) => (deleted ? 'calc(100% - 32px)' : '100%')};
  ${overflowStyles};
`;

const Wrapper = styled.div<{defaultType: number; bgc: string; offsetX: number}>`
  height: 96px;
  padding: 14px 16px;
  box-sizing: border-box;
  overflow: ${({offsetX}) => (offsetX < 0 ? 'visible' : 'hidden')};
  background-color: ${({theme, bgc}) => theme.colors[bgc]};
  display: flex;
  column-gap: 8px;
  cursor: default;
  border-bottom: 1px solid
    ${({
      theme: {
        colors: {blue100, grey030},
      },
      defaultType,
    }) => (defaultType ? blue100 : grey030)};

  transform: ${({offsetX}) => `translateX(${offsetX}px)`};
  position: relative;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transition: all 0.35s ease-out;
    background-color: ${({
      theme: {
        colors: {grey010, grey030},
      },
      defaultType,
    }) => (defaultType ? grey030 : grey010)};
    border-bottom: 1px solid
      ${({
        theme: {
          colors: {blue100, grey010},
        },
        defaultType,
      }) => (defaultType ? blue100 : grey010)};

    ${Created} {
      display: none;
    }

    ${MoreIcon} {
      display: block;
      fill: ${({theme}) => theme.colors.grey080};
    }

    ${PinIcon}, ${LockIcon} {
      fill: ${({theme}) => theme.colors.grey080};
    }

    ${Messages} {
      color: ${({theme}) => theme.colors.grey000};
      background-color: ${({theme}) => theme.colors.blue100};
    }

    ${Title} , ${Author} {
      color: ${({theme}) => theme.colors.grey100};
    }

    ${Message} {
      color: ${({theme}) => theme.colors.grey090};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 23px;
  overflow: hidden;
  margin-bottom: 4px;
  width: 100%;
  flex-shrink: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: auto;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeartIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.red100};
  color: ${({theme}) => theme.colors.grey000};
  font-size: 10px;
  line-height: 1;
`;

const SwapPinContainer = styled.div`
  ${font_large};
  ${boldWeight};
  width: 75px;
  height: 96px;
  background-color: ${({theme}) => theme.colors.green090};
  color: ${({theme}) => theme.colors.grey000};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  position: absolute;
  right: -75px;
  top: 0;
`;

const Attachment = styled.div`
  ${font_small};
  color: ${({theme}) => theme.colors.blue094};
  ${overflowStyles};
  width: 100%;
`;

const PinIconSwap = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.grey000};
  width: 30px;
  height: 30px;
`;

export default ChatCell;
