export default {
  INSTANT_QUOTE: 'Instant Quote',
  ENTER_DETAILS: "Enter your project's details to get a quote, or reload your account credits.",
  WORD_COUNT: 'Word count:',
  PURCHASE_CREDITS: 'Purchase credits',
  GET_QUOTE: 'Get Quote',
  PROJECT_QUOTE: 'Project Quote',
  ORDER_ID: 'Order ID',
  TOTAL_PAYMENT: 'Total Payment',
  COST: 'Cost',
  IN_CREDITS: 'in credits',
  TOTAL: 'TOTAL',
  BUY_NOW: 'Buy Now',
  EXPERT_TYPE: 'Expert type',
  ADD_EDITING: 'Add Editing',
  DISCOUNT_PER_LANG: 'discount per language',
  TRANSLATION: 'Translation',
  EDITING: 'Editing',
  SOURCE_LANG: 'Source Language',
  VAT: 'VAT',
  ENTER_VOUCHER: 'Enter Voucher Code',
  VOUCHER_PLACEHOLDER: 'Add Promo code here',
  APPLY: 'APPLY',
  MODAL_ERROR_TITLE: 'Oops, This Is Awkward',
  MODAL_SUCCESS_TITLE: 'Coupon accepted',
  MODAL_ERROR_TEXT:
    "As much as we would love to see you enjoy the voucher, we're sorry to say that the code you entered is not recognized by our system. Voucher codes are case sensitive, so make sure to use the correct uppercase and lowercase letters. Still not working? Our customer support will be glad to help.",
  MODAL_SUCCESS_TEXT:
    'Your account has been credited! You are invited to order your translation project now. Simply follow the wizard on our home page. Thank you for using BLEND!',
  VOUCHER_VALUE: 'Voucher Value',
  PROMOTION: 'Promotion',
  DISCOUNT: 'Discount',
  USE_FROM_BALANCE: 'Use from balance',
  PREV_DEBT: 'Previous Debt',
  TOTAL_INC_VAT: 'Total inc. VAT',
  WORD_COUNT_ERROR: 'Please choose your word count',
  RECHARGE_AMOUNT_ERROR: 'Please choose you recharge amount',
  WC_SOURCE_LANG_ERROR: 'Please select the language you are translating your text from',
  WC_TARGET_LANG_ERROR: 'Please select the language(s) you need your content translated to',
  FOR: 'for',
  DATA_QA: {
    WORD_COUNT: 'payment_quote_word_count',
    PURCHASE_CREDITS: 'payment_quote_purchase_credits',
    QUOTE_CONTAINER: 'payment-quote-container'
  }

};
