import queryString from 'query-string';

import core from './core';
import {
  ApiAddBudgetResponse,
  ApiExpertiseResponse,
  ApiPOResponse,
  ApiResponse,
  ApiSessionResponse,
  ApiUiFeatureResponse,
  CurrencyRegistry,
  LoginRequestParams,
  PostCreatePasswordParams,
  PostSignupParams,
} from 'pages/Wizard/types';

export const getLanguages = () => core.get('/registry/get?data=Language');

export const getSourceLanguages = () =>
  core.get('/registry/get?data=Language').then(({data}) => {
    const available = Object.keys(data.results?.Language?.grouped?.languages).map(
      (key) => data.results?.Language?.grouped?.languages[key].slug
    );
    return data.results?.Language?.list.filter((value: any) => available.indexOf(value.slug) >= 0);
  });

export const getTargetLanguages = (sourceLanguage: string) =>
  core.get(`api/2/wizard/getavailablelanguagesBySlug?slug=${sourceLanguage}`).then(({data}) => data);

export const getUiFeatures = (): Promise<ApiUiFeatureResponse> =>
  core
    .get('api/2/site/ui-feature/config/get?data=UiFeature')
    .then(({data}) => data)
    .catch((err) => console.error(err));
export const getPrices = () => core.get('/registry/get?data=Price').then(({data}) => data);

export const deleteSNData = (): Promise<never> => core.delete('api/2/account/sn-info');

export const getExpertises = (): Promise<ApiExpertiseResponse> =>
  core.get('/registry/get?data=Expertise').then(({data}) => data);

export const getDefaultCurrency = (): Promise<ApiResponse<CurrencyRegistry>> =>
  core
    .get('/registry/get?data=Currency')
    .then(({data}) => data)
    .catch((err) => console.error(err));
export const getPO = (pid?: string): Promise<ApiPOResponse> =>
  core
    .get('/registry/get?data=PurchaseOrder', {params: {pid}})
    .then((data) => data.data)
    .catch((err) => console.error(err));

export const getUserData = (): Promise<any> => core.get('/registry/get?data=User').catch((err) => console.error(err));
export const postLoginUser = (params: LoginRequestParams, config: any): Promise<any> =>
  core.post(config.authPath, params, {
    baseURL: process.env.REACT_APP_AUTH_URL ?? config.authServer,
    withCredentials: true,
  });

export const postSignupUser = (params: PostSignupParams): any =>
  core.post(
    '/userService/addAccountInfo',
    queryString.stringify(params, {
      skipNull: true,
    })
  );

export const postLogout = (formData: any) => core.post('/ajax/user/logout', formData);

export const postResetPassword = (email: string, CSRFToken: string) =>
  core.post(
    '/api/2/account/auth/password/resetPasswordToken',
    queryString.stringify(
      {email, CSRFToken},
      {
        skipNull: true,
      }
    )
  );

export const postCheckToken = (token: string) =>
  core
    .post(
      '/ajax/user/checkToken',
      queryString.stringify(
        {token},
        {
          skipNull: true,
        }
      )
    )
    .then(({data}) => data);

export const postCreatePassword = (params: PostCreatePasswordParams) =>
  core.post(
    `/api/2/account/auth/password?token=${params.token}`,
    queryString.stringify(params, {
      skipNull: true,
    })
  );

export const getCountriesInfo = () => core.get('/userService/getCountriesInfo');

export const postUpdateRegisterInfo = (CSRFToken: string, params: any) =>
  core.post(
    `/userService/updateRegisterInfo?CSRFToken=${CSRFToken}`,
    queryString.stringify(params, {
      skipNull: true,
    })
  );

export const getRegistry = (): Promise<ApiSessionResponse> =>
  core.get('/registry/get?data=Session').then(({data}) => data);

export const getOHTConfig = (): Promise<any> => core.get('/registry/get?data=Config').then(({data}) => data);

export const postPO = ({fd}: any): Promise<any> => core.post('/api/2/wizard/pricequote', fd);

export const getCartByAmount = (amount: string): Promise<ApiAddBudgetResponse> => {
  const fd = new FormData();
  fd.append('sum', amount);
  fd.append('type', 'budget');
  fd.append('name', 'cart');
  return core.post('/api/2/calc', fd).then(({data}) => data);
};

export const postFilestackData = ({fd}: any): Promise<any> =>
  core.post('/ajax/filepicker/fileUploaded', fd).then(({data}) => data);

export const postManualTextResource = ({fd}: any): Promise<any> =>
  core.post('/wizard/updateText', fd).then(({data}) => data);

export const finalizePO = ({fd}: any): Promise<any> => core.post('/api/2/wizard/finalize', fd).then(({data}) => data);

export const calculateInstantPriceQuote = ({fd}: any): Promise<any> =>
  core.post('/api/2/calc', fd).then(({data}) => data);

export const getMPTELightInfo = (poid: string): Promise<any> =>
  core.get(`/api/2/wizard/mt-available-params?poid=${poid}`).then(({data}) => data?.results);

export const notifyHubspotRegistration = (config: any, hubspotData: any): Promise<any> => {
  const defaults = {
    ...{
      firstname: '',
      lastname: '',
      email: '',
      industry_custom: '',
      company: '',
      business_personal__c: '',
      oht_user_id__c: '',
      ip_address__c: '',
    },
    ...hubspotData,
  };
  const cookieParts = ('; ' + document.cookie).split('; hubspotutk=');
  const payload = {
    submittedAt: Date.now(),
    fields: Object.keys(defaults).map((key) => ({
      name: key,
      value: defaults[key],
    })),
    context: {
      hutk: cookieParts.length === 2 ? cookieParts.pop()?.split(';').shift() : '',
      pageUri: document.location.href,
      pageName: document.title,
      ipAddress: defaults.ip_address__c,
    },
  };

  return core
    .post(config.hubspotApi + config.hubspotRegisterFormUUid, payload)
    .then(({data}) => data)
    .catch((error) => console.error(error));
};

export const notifyHubspotFinishLater = (config: any, email: string): Promise<any> => {
  const cookieParts = ('; ' + document.cookie).split('; hubspotutk=');
  const payload = {
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
    context: {
      hutk: cookieParts.length === 2 ? cookieParts.pop()?.split(';').shift() : '',
      pageUri: document.location.href,
      pageName: document.title,
    },
  };
  return core
    .post(config.hubspotApi + config.hubspotFinishLaterUUid, payload)
    .then(({data}) => data)
    .catch((error) => console.error(error));
};

export const sendFinishLaterEmail = (email: string, step: string): Promise<any> =>
  core.post('/api/2/po/finish-later', queryString.stringify({email, step})).then(({data}) => data);

export const saveProjectBrief = (CSRFToken: string, pid: string, note: string) => {
  const fd = new FormData();
  fd.append('pid', pid);
  fd.append('note', note);
  fd.append('CSRFToken', CSRFToken);
  return core.post('/api/2/project/brief', fd).then(({data}) => data);
};

export const saveTranslationCategory = (CSRFToken: string, pid: string, category: string) => {
  const fd = new FormData();
  fd.append('pid', pid);
  fd.append('category', category);
  fd.append('CSRFToken', CSRFToken);
  return core.post('/api/2/project/translation-category', fd).then(({data}) => data);
};

export const addReferenceFiles = (CSRFToken: string, id: number, uploadedFile: File) => {
  const fd = new FormData();
  fd.append('context', 'reference');
  fd.append('@upload', uploadedFile, uploadedFile.name);
  fd.append('CSRFToken', CSRFToken);
  return core.post(`/api/2/projects/${id}/addresource`, fd).then(({data}) => data);
};
