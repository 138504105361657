import {makeObservable, observable, action, computed} from 'mobx';
import {ApiService} from '../../services/apiService';
import mockAPIResponseFreeReport from 'pages/Editor/mockAPIResponseFreeReport.json';
import mockAPIResponseDetailedReport from 'pages/Editor/mockAPIResponseDetailedReport.json';
import mockAPIResponseFromPYN from 'pages/Editor/mockAPIResponseFromPYN.json';
import Consts, {KycVersionTypes} from 'pages/Editor/EditorConst';

const {
  RESOURCE_TYPES: {DETAILED, KYC},
} = Consts;

export interface IResourceTaskFields {
  [key: string]: string | string[] | boolean | undefined | IResourceTaskFields | null;
}

export interface IResourceTaskData {
  [key: string]: string | string[] | boolean | undefined | IResourceTaskFields | null;
}

export interface IResourceTaskDataDto extends IResourceTaskData {
  form_type?: string;
  fields?: IResourceTaskFields;
  formType?: string;
}

export interface IResourceTask {
  id?: string;
  blend_reference_id?: string;
  data: IResourceTaskData;
}

export interface IResourceTasksObject {
  [key: string]: IResourceTask;
}

export interface IKycTaskListItem {
  label: string;
  value: string;
}

export interface IKycTaskField {
  default?: string | boolean;
  description?: string | null;
  label: string;
  name: string;
  type: string;
  list?: IKycTaskListItem[];
  multiSelect?: boolean;
}

export interface IKYCTask {
  available_form_types?: string;
  callback_url: string;
  client_ref: string;
  country: string;
  default_form_type?: string;
  file_rsc_uid: string[];
  language: string;
  task_type: KycVersionTypes;
  task_uuid: string;
  category?: string;
  fields: IKycTaskField[];
}

export interface IKYCTasksList {
  data: IKYCTask[];
}

export interface IResource {
  source_uuid?: string;
  task_type?: string;
  type?: string;
  tasks?: IResourceTasksObject;
  data?: IKYCTasksList;
}

export function editorsPageStore() {
  return makeObservable(
    {
      resource: {} as IResource,
      metaData: {} as any,
      projectId: '' as string,
      CSRFToken: null as null | string,
      isAdmin: false as boolean,
      resourceType: 'free' as string,
      get readOnly() {
        return !this.metaData.editor?.permission.includes('write');
      },
      setResourceType(type: string) {
        this.resourceType = type;
        const projectIdFromUrl = new URLSearchParams(window.location.search).get('project_id');
        const resourceUuidFromUrl = new URLSearchParams(window.location.search).get('resource_uuid');
        const tokenFromUrl = new URLSearchParams(window.location.search).get('csrftoken');

        if (projectIdFromUrl) {
          this.projectId = projectIdFromUrl;
          this.CSRFToken = tokenFromUrl;
        }

        this.fetchResource(projectIdFromUrl || '', resourceUuidFromUrl || '', tokenFromUrl || '');
      },
      async fetchResource(projectIdFromUrl: string, resourceUuidFromUrl: string, tokenFromUrl: string) {
        try {
          let metadata = await ApiService.getResource(projectIdFromUrl, resourceUuidFromUrl, tokenFromUrl);
          if (!metadata) {
            console.log('Loading Mock');
            switch (this.resourceType) {
              case DETAILED:
                metadata = mockAPIResponseDetailedReport;
                break;
              case KYC:
                console.log('kyc....', mockAPIResponseFromPYN);
                break;
              default:
                metadata = mockAPIResponseFreeReport;
                break;
            }
          }

          this.resource = metadata?.data || null;
          this.metaData = {...metadata};
          this.isAdmin = metadata?.current_user?.role === 'admin';
        } catch (error) {
          console.log('some error');
        }
      },
    },
    {
      resource: observable,
      metaData: observable,
      projectId: observable,
      CSRFToken: observable,
      isAdmin: observable,
      resourceType: observable,
      readOnly: computed,
      setResourceType: action.bound,
      fetchResource: action.bound,
    }
  );
}
