import React, {FC} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useUser} from 'context/UserData';

interface TranslatorRouteProps {
  path?: string;
}

const HomeLayout: FC<TranslatorRouteProps> = () => {
  const {userData, isSuccess} = useUser();
  const location = useLocation();

  let targetPath = '/auth';
  if (userData) {
    targetPath = userData.type === 'Translator' ? '/dashboard' : '/cpanel';
  }

  if (isSuccess && targetPath !== '/auth') {
    return <Navigate to={targetPath} state={{from: location.pathname}} />;
  } else if (isSuccess && targetPath === '/auth') {
    window.location.href = '/auth';
  }

  return null;
};

export default HomeLayout;
