import React from 'react';
import {affiliateRevenuesStore} from 'store/pages/affiliateRevenuesStore';
import {customerStatementStore} from 'store/pages/customerStatementStore';
import {invoicesStore} from 'store/pages/invoicesStore';
import {messageBoxStore} from 'store/pages/messageBoxStore';
import {clientAlertsStore} from 'store/pages/clientAlertsStore';
import {createPasswordStore} from 'store/pages/createPasswordStore';
import {editorsPageStore} from 'store/pages/editorsPageStore';
import {profileStore} from 'store/pages/profileStore';
import {accountInformationStore} from 'store/components/accountInformationStore';
import {languageSkillsStore} from 'store/components/languageSkillsStore';
import {expertiseStore} from 'store/components/expertiseStore';
import {billingAddressStore} from 'store/components/billingAddressStore';
import {resumeCertificatesStore} from 'store/components/resumeCertificatesStore';
import {availabilityStore} from 'store/components/availabilityStore';
import {referencesStore} from 'store/components/referencesStore';
import {SMSVerificationStore} from 'store/components/SMSVerificationStore';
import {softwareServicesStore} from 'store/components/softwareServicesStore';
import {mailingPreferencesStore} from 'store/components/mailingPreferencesStore';
import {APIKeysStore} from 'store/components/APIKeysStore';
import {preferredTranslatorsStore} from 'store/components/preferredTranslatorsStore';
import {translatorGuidelinesStore} from 'store/components/translatorGuidelinesStore';
import {translationMemoryStore} from 'store/components/translationMemoryStore';
import {terminologyStore} from 'store/components/terminologyStore';
import {passwordUpdateStore} from 'store/components/passwordUpdateStore';
import {telegramNotificationsStore} from 'store/components/telegramNotificationsStore';
import {beneficiaryAccountsStore} from 'store/components/beneficiaryAccountsStore';
import {projectNotesStore} from 'store/components/projectNotesStore';
import {glossaryFilesStore} from 'store/components/glossaryFilesStore';
import {enableTFAStore} from 'store/components/enableTFAStore';
import {privacyPolicyStore} from 'store/components/privacyPolicyStore';
import {LmDashboardMainStore} from 'store/components/LmDashboardMainStore';
import {LmDashboardProjectsStore} from 'store/components/LmDashboardProjectsStore';
import {creditActionsStore} from 'store/components/creditActionsStore';
import {registrationStore} from 'store/pages/registrationStore';
import {resetPasswordStore} from 'store/pages/resetPasswordStore';
import {resourceViewStore} from 'store/pages/resourceViewStore';
import {wizardStore} from 'store/pages/wizardStore';
import {shoppingCartStore} from 'store/pages/shoppingCartStore';
import {layoutStore} from 'store/pages/layoutStore';
import {voicesStore} from 'store/pages/voicesStore';
import {dashboardStore} from 'store/pages/dashboardStore';
import {backofficeStore} from 'store/pages/backofficeStore';
import {blendTalkStore} from 'store/pages/blendTalkStore';
import {blendTalkForAdminStore} from 'store/pages/blendTalkForAdminStore';
import {vocabularyStore} from 'store/pages/vocabularyStore';
import {moderationStore} from 'store/pages/moderationStore';
import {myProjectsStore} from 'store/pages/myProjectsStore';
import {translatorMyProjectsStore} from 'store/pages/translatorMyProjectsStore';
import {availableProjectsStore} from 'store/pages/availableProjectsStore';
import {probationManagementStore} from 'store/pages/probationManagementStore';
import {convertStore} from 'store/pages/convertStore';
import {paymentQuoteStore} from 'store/pages/paymentQuoteStore';
import {translatorMyTeamStore} from 'store/pages/translatorMyTeamStore';
import {themeOptionsStore} from 'store/components/themeOptionsStore';

export const storesContext = React.createContext({
  affiliateRevenuesStore: affiliateRevenuesStore(),
  customerStatementStore: customerStatementStore(),
  invoicesStore: invoicesStore(),
  messageBoxStore: messageBoxStore(),
  clientAlertsStore: clientAlertsStore(),
  createPasswordStore: createPasswordStore(),
  editorsPageStore: editorsPageStore(),
  profileStore: profileStore(),
  accountInformationStore: accountInformationStore(),
  languageSkillsStore: languageSkillsStore(),
  expertiseStore: expertiseStore(),
  billingAddressStore: billingAddressStore(),
  resumeCertificatesStore: resumeCertificatesStore(),
  availabilityStore: availabilityStore(),
  referencesStore: referencesStore(),
  SMSVerificationStore: SMSVerificationStore(),
  softwareServicesStore: softwareServicesStore(),
  mailingPreferencesStore: mailingPreferencesStore(),
  APIKeysStore: APIKeysStore(),
  preferredTranslatorsStore: preferredTranslatorsStore(),
  translatorGuidelinesStore: translatorGuidelinesStore(),
  translationMemoryStore: translationMemoryStore(),
  terminologyStore: terminologyStore(),
  passwordUpdateStore: passwordUpdateStore(),
  telegramNotificationsStore: telegramNotificationsStore(),
  beneficiaryAccountsStore: beneficiaryAccountsStore(),
  projectNotesStore: projectNotesStore(),
  glossaryFilesStore: glossaryFilesStore(),
  enableTFAStore: enableTFAStore(),
  privacyPolicyStore: privacyPolicyStore(),
  LmDashboardMainStore: LmDashboardMainStore(),
  LmDashboardProjectsStore: LmDashboardProjectsStore(),
  registrationStore: registrationStore(),
  resetPasswordStore: resetPasswordStore(),
  resourceViewStore: resourceViewStore(),
  wizardStore: wizardStore(),
  shoppingCartStore: shoppingCartStore(),
  voicesStore: voicesStore(),
  layoutStore: layoutStore(),
  creditActionsStore: creditActionsStore(),
  dashboardStore: dashboardStore(),
  backofficeStore: backofficeStore(),
  blendTalkStore: blendTalkStore(),
  blendTalkForAdminStore: blendTalkForAdminStore(),
  vocabularyStore: vocabularyStore(),
  moderationStore: moderationStore(),
  myProjectsStore: myProjectsStore(),
  translatorMyProjectsStore: translatorMyProjectsStore(),
  availableProjectsStore: availableProjectsStore(),
  probationManagementStore: probationManagementStore(),
  convertStore: convertStore(),
  paymentQuoteStore: paymentQuoteStore(),
  translatorMyTeamStore: translatorMyTeamStore(),
  themeOptionsStore: themeOptionsStore(),
});
