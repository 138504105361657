import React, {FC, useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Person} from '@mui/icons-material';
import BaseIcon from '../BaseComponents/BaseIcon';
import BaseImage from '../BaseComponents/BaseImage';
import UserBar from './MainHeader/OHTUserBar';
import {
  controlPanelLinks,
  customerProfileSubmenu,
  customerSecuritySubmenu,
  financialsSubmenu,
  MainMenuItem,
  MyTeamSubMenu,
  ProfileSubMenu,
  SupportSubMenu,
  translationLinks,
  translationManagmentSubmenu,
} from './SideBarLinks';
import SidebarItem from './SidebarItem/SidebarItem';
import SmallSidebarItem from './SmallSidebarItem';
import Control from 'assets/icons_refactor/SideMenu/side-control.svg';
import BlendTalk from 'assets/icons_refactor/SideMenu/side-chat.svg';
import Settings from 'assets/icons_refactor/SideMenu/side-settings.svg';
import Support from 'assets/icons_refactor/SideMenu/side-support.svg';
import sideProjectsIcon from 'assets/icons_refactor/SideMenu/side-doc.svg';
import sideAvailableIcon from 'assets/icons_refactor/SideMenu/side-available.svg';
import Management from 'assets/icons_refactor/SideMenu/management.svg';
import SideBalance from 'assets/icons_refactor/SideMenu/round-statement.svg';
import Guest from 'assets/icons_refactor/Header/avatar.svg';
import Profile from 'assets/icons_refactor/SideMenu/about-me.svg';
import Security from 'assets/icons_refactor/SideMenu/security.svg';
import SideCommunity from 'assets/icons_refactor/SideMenu/side-community.svg';
import {USER_ROLES} from 'utils/const';
import useAuthToken from 'hooks/useAuthToken';
import useConfig from 'hooks/useConfig';
import useMobile from 'hooks/useMobile';
import {useUser} from 'context/UserData';
import {useStores} from 'hooks/useStores';
import {sanitizePath} from './PageLayoutUtils';

const {admin, customer, translator} = USER_ROLES;

interface SidebarProps {
  openSideBar: (key?: string) => void;
  initiallySelectedNav: string;
  setInitiallySelectedNav: (key: string) => void;
}

export const Sidebar: FC<SidebarProps> = observer(({openSideBar, initiallySelectedNav, setInitiallySelectedNav}) => {
  const {
    config: {isShowBlendTalkFeature},
  } = useConfig();

  const {token} = useAuthToken();

  const {pathname, hash: locationHash} = useLocation();

  const {
    layoutStore: {sidebarToggle, setSidebarToggle},
    profileStore: {profileData},
    blendTalkStore: {getCurrentUser, getChatList, chatList, currentUser},
    translatorMyTeamStore: {getMyTeamAvailability, isMyTeamAvailable},
  } = useStores();

  const {isLoggedIn, userData} = useUser();

  const {isLaptop} = useMobile();
  const {myTeamStatus, thumb: {src = null} = {}, type: userType = customer} = userData ?? {};

  useEffect(() => {
    if (token) {
      getCurrentUser(token);
      getChatList(token);
      if (userType === translator) {
        getMyTeamAvailability();
      }
    }
  }, [token]);

  const showBlendTalkLikeFeature = useMemo(() => (isShowBlendTalkFeature ? [customer] : []), [isShowBlendTalkFeature]);

  const blendTalkUserTypes = useMemo(
    () => (currentUser?.type === 'translator' && chatList?.length ? [customer, translator] : showBlendTalkLikeFeature),
    [showBlendTalkLikeFeature, currentUser, chatList?.length]
  );

  const customerNavMenu = useMemo<MainMenuItem[]>(
    () => [
      {
        key: 'dashboard',
        name: 'Dashboard',
        to: '/cpanel',
        icon: Control,
      },
      {
        key: 'projects',
        name: 'My projects',
        to: '/my-projects/customer/',
        icon: sideProjectsIcon,
      },
      {
        key: 'translationManagement',
        name: 'Translation Management',
        type: 'loggedIn',
        icon: Management,
        submenu: (myTeamStatus ? MyTeamSubMenu : []).concat(translationManagmentSubmenu),
      },
      {
        key: 'financials',
        name: 'Financials',
        type: 'loggedIn',
        icon: SideBalance,
        submenu: financialsSubmenu,
      },
      {
        key: 'translationSettings',
        name: 'Translation Settings',
        icon: Settings,
        submenu: translationLinks,
      },
      {
        key: 'blendTalk',
        name: 'BLENDTalk',
        type: 'loggedIn',
        to: '/blend-talk/chat/',
        icon: BlendTalk,
        userTypes: blendTalkUserTypes,
      },
      {
        key: 'profile',
        name: 'Profile',
        icon: Profile,
        submenu: customerProfileSubmenu,
      },
      {
        key: 'security',
        name: 'Security',
        icon: Security,
        submenu: customerSecuritySubmenu,
      },
      {
        key: 'support',
        name: 'Support',
        type: 'all',
        icon: Support,
        submenu: SupportSubMenu,
      },
    ],
    [blendTalkUserTypes, myTeamStatus]
  );

  const translatorAndAdminNavMenu = useMemo<MainMenuItem[]>(
    () => [
      {
        key: 'controlPanel',
        name: 'Control panel',
        icon: Control,
        type: 'loggedIn',
        submenu: controlPanelLinks,
      },
      {
        key: 'availableProjects',
        name: 'Open projects',
        href: '/open-project-freelance-translator',
        userTypes: [translator],
        icon: sideAvailableIcon,
      },
      {
        key: 'projects',
        name: 'My projects',
        href: '/my-projects/translator',
        userTypes: [translator],
        icon: sideProjectsIcon,
      },
      {
        key: 'projects-admin',
        name: 'My projects',
        to: '/my-projects/customer/',
        userTypes: [admin],
        icon: sideProjectsIcon,
      },
      {
        key: 'my-team',
        name: 'MyTeam',
        to: '/my-team',
        type: 'loggedIn',
        icon: SideCommunity,
        invisible: !isMyTeamAvailable,
      },
      {
        key: 'blendTalk',
        name: 'BLENDTalk',
        type: 'loggedIn',
        to: '/blend-talk/chat/',
        icon: BlendTalk,
        userTypes: blendTalkUserTypes,
      },
      {
        key: 'profile',
        name: 'Profile',
        icon: Person as any,
        submenu: ProfileSubMenu,
      },
      {
        key: 'support',
        name: 'Support',
        type: 'all',
        icon: Support,
        submenu: SupportSubMenu,
      },
    ],
    [blendTalkUserTypes, isMyTeamAvailable]
  );

  const navMenu = useMemo<MainMenuItem[]>(
    () => (userType === customer ? customerNavMenu : translatorAndAdminNavMenu),
    [customerNavMenu, translatorAndAdminNavMenu, userType]
  );

  useEffect(() => {
    //this effect will update sidebar status when arriving to a specific location
    const currentMenu = navMenu.find(({submenu}) =>
      submenu?.find(({to}) => sanitizePath(to) === `${sanitizePath(pathname)}${locationHash}`)
    );
    if (currentMenu) {
      setInitiallySelectedNav(currentMenu.key);
    } else if (pathname === '/profile/') {
      setInitiallySelectedNav('profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, locationHash, navMenu]);

  const getAvailableSubMenuItems = useCallback(
    ({submenu, type: mainType}: MainMenuItem) =>
      submenu?.filter(
        ({userTypes, type: tabType, key}) =>
          (!userTypes || userTypes.includes(userType)) &&
          (!tabType || (tabType === 'loggedIn' && isLoggedIn)) &&
          (mainType === 'all' ||
            (mainType === 'loggedIn' && isLoggedIn) ||
            profileData?.sections?.find((section) => {
              return [section, 'myTeam', 'ourPartners', 'apiDocumentation', 'accountInformation', 'messages'].includes(
                key
              );
            }))
      ) || [],
    [isLoggedIn, profileData, userType]
  );

  const getMenuItemClasses = useCallback(
    (key: string, to?: string) => `${initiallySelectedNav === key || (to && pathname.includes(to)) ? 'active' : ''} `,
    [initiallySelectedNav, pathname]
  );

  const handleOpenClosedSidebar = useCallback((key = '') => openSideBar(key), [openSideBar]);
  const handleClickAvater = useCallback(() => openSideBar(''), [openSideBar]);
  const sidebarSize: number = useMemo(() => (sidebarToggle ? 280 : 88), [sidebarToggle]);

  return (
    <div>
      {sidebarToggle ? (
        <SidebarOpen isLaptop={isLaptop} sidebarSize={sidebarSize}>
          <UserBar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          {navMenu.map((item) => {
            const availableSubMenus = getAvailableSubMenuItems(item);
            if (!item.invisible && (!item.userTypes || item.userTypes.includes(userType))) {
              return (
                <SidebarItem
                  isStartOpen={initiallySelectedNav === item.key}
                  item={item}
                  key={item.key}
                  availableSubMenus={availableSubMenus}
                  openSideBar={openSideBar}
                />
              );
            }
          })}
        </SidebarOpen>
      ) : (
        <SidebarBlock sidebarSize={sidebarSize}>
          <User onClick={handleClickAvater}>
            {src ? <Avatar src={src} /> : <NoAvatar icon={Guest} boxW={37} boxH={37} />}
            <Separator />
          </User>
          <SidebarClose>
            {navMenu.map((item) => (
              <SmallSidebarItem
                key={item.key}
                item={item}
                getMenuItemClasses={getMenuItemClasses}
                onOpenClosedSidebar={handleOpenClosedSidebar}
                userType={userType}
              />
            ))}
          </SidebarClose>
        </SidebarBlock>
      )}
    </div>
  );
});

const SidebarOpen = styled.div<{isLaptop: boolean; sidebarSize: number}>`
  overflow-y: auto;
  margin-top: 2px;
  box-shadow: ${({isLaptop}) => `${isLaptop ? 'none' : '1px -1px 6px 0 rgb(200, 200, 200)'}`};
  z-index: 3;
  transition: all 1s ease-out;
  position: fixed;
  height: calc(100% - 90px);
  width: ${({sidebarSize}) => sidebarSize}px;
  background: ${({theme}) => theme.colors.grey000};

  &::-webkit-scrollbar {
    width: 0.4vw;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193, 0.5);
    border-radius: 50px;
  }

  // @media ${({theme}) => theme.breakpoints.maxMd} {
  //   background: ${({theme}) => theme.colors.grey000};
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 10;
  // }
`;

const SidebarBlock = styled.div<{sidebarSize: number}>`
  transition: all 0.35s ease-out;
  position: fixed;
  z-index: 1;
  height: calc(100% - 90px);
  width: ${({sidebarSize}) => sidebarSize}px;
  background-color: ${({theme}) => theme.colors.grey000};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const User = styled.div`
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;

const Avatar = styled(BaseImage)`
  width: 50px;
  height: 50px;
  border-radius: 26px;
  margin-bottom: inherit;
`;

const NoAvatar = styled(BaseIcon)`
  width: 50px;
  height: 50px;
  border-radius: 26px;
  margin-bottom: inherit;
  margin-left: 10px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${({theme}) => theme.colors.grey020};
`;

const SidebarClose = styled.div`
  display: grid;
  padding: 30px 10px 20px 10px;
  gap: 40px;
  justify-items: center;
  box-sizing: border-box;
`;
