import React, {FC, useMemo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import InfoOutlined from 'assets/icons_refactor/Wizard/info-outlined.svg';

interface Props {
  password: string;
  onApproveChange: (value: boolean) => void;
  mobileFullWidth?: number;
}

const PasswordRequirements: FC<Props> = ({password, onApproveChange, mobileFullWidth}) => {
  const [checkLetter, setCheckLetter] = useState<boolean>(false);
  const [checkCapitalLetter, setCheckCapitalLetter] = useState<boolean>(false);
  const [checkNumber, setCheckNumber] = useState<boolean>(false);
  const [checkSpecial, setCheckSpecial] = useState<boolean>(false);
  const [checkCount, setCheckCount] = useState<boolean>(false);
  const [checkSpace, setCheckSpace] = useState<boolean>(true);

  const passwordApproved: boolean = useMemo(
    () => checkLetter && checkCapitalLetter && checkNumber && checkSpecial && checkCount && !checkSpace,
    [checkCapitalLetter, checkCount, checkLetter, checkNumber, checkSpace, checkSpecial]
  );

  useEffect(() => {
    onApproveChange(passwordApproved);
  }, [onApproveChange, passwordApproved]);

  const checkPassword = useCallback((value: string) => {
    // At least one UPPERCASE character:
    const upperCasePattern = /^(?=.*[A-Z]).+$/;

    // At least one LOWERCASE character:
    const lowerCasePattern = /^(?=.*[a-z]).+$/;

    // At least one NUMBER:
    const numberPattern = /^(?=.*\d).+$/;

    // At least one SPECIAL character:
    const specialCharacterPatter = /([-+=_!@#$%^&*.,;:'"<>/?`~[\](){}\\|\s])/;

    // At least 6 characters in the screen:
    const characterCountPattern = /^.{6,}/;

    // Check for Space:
    const spacePattern = /^(?=.*[ ]).+$/;

    setCheckLetter(lowerCasePattern.test(value));
    setCheckCapitalLetter(upperCasePattern.test(value));
    setCheckNumber(numberPattern.test(value));
    setCheckSpecial(specialCharacterPatter.test(value));
    setCheckCount(characterCountPattern.test(value));
    setCheckSpace(spacePattern.test(value));
  }, []);

  useEffect(() => {
    checkPassword(password);
  }, [checkPassword, password]);

  return (
    <Wrapper container spacing={2}>
      {passwordApproved ? (
        <ApprovedMessage item xs={12} md={12}>
          <CheckCircleOutlineIcon /> Your password is secure and you're all set!
        </ApprovedMessage>
      ) : (
        <CheckBlock mobilefullwidth={mobileFullWidth}>
          <Grid item xs={12} sm={6}>
            <div data-qa-auto="password-requirements-tip" className={`item ${checkCapitalLetter ? 'completed' : ''}`}>
              One uppercase Latin letter
            </div>
            <div data-qa-auto="password-requirements-tip" className={`item ${checkLetter ? 'completed' : ''}`}>
              One lowercase Latin letter
            </div>
            <div data-qa-auto="password-requirements-tip" className={`item ${checkNumber ? 'completed' : ''}`}>
              One number
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div data-qa-auto="password-requirements-tip" className={`item ${checkCount ? 'completed' : ''}`}>
              At least 6 characters long
            </div>
            <SpecialSymbolsItem
              data-qa-auto="password-requirements-tip"
              className={`item ${checkSpecial ? 'completed' : ''}`}
            >
              One special character
              <SymbolTooltip title="!, ?, $, %, etc" key="specialSymbols">
                <Box>
                  <InfoIcon icon={InfoOutlined} />
                </Box>
              </SymbolTooltip>
            </SpecialSymbolsItem>
            <div data-qa-auto="password-requirements-tip" className={`item ${checkSpace ? 'showSpace' : 'hideSpace'}`}>
              No spaces
            </div>
          </Grid>
        </CheckBlock>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)`
  padding: 10px;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
`;

const ApprovedMessage = styled(Grid)`
  display: flex;
  margin: 8px 0 8px 16px;
  background: rgb(221, 222, 255);
  align-items: center;
  font-weight: 500;
  border-radius: 4px;
  padding-bottom: 16px;

  & svg {
    margin-right: 10px;
    color: rgb(56, 56, 56);
  }
`;

const CheckBlock = styled.div<{mobilefullwidth?: number}>`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: ${({mobilefullwidth}) => (mobilefullwidth ? '0' : '0 10%')};

    & div:nth-child(1) {
      padding-bottom: 0;
    }

    & div:nth-child(2) {
      padding-top: 0;
    }
  }

  & > div {
    padding: 8px;

    & .item {
      margin-bottom: 5px;

      &:before {
        width: 8px;
        height: 8px;
        content: '';
        display: inline-block;
        background: #575dff;
        margin-right: 10px;
        border-radius: 8px;
      }

      &.completed {
        color: #bdbbb9;

        &:before {
          background: #bdbbb9;
        }
      }

      &.showSpace {
        margin-bottom: 0;
        color: #000000;
        max-height: inherit;
        transition: color 1s, transform 1s;

        &:before {
          background: #575dff;
          transition: background 1s, transform 1s;
        }
      }

      &.hideSpace {
        margin-bottom: 0;
        color: transparent;
        max-height: 0;

        &:before {
          background: transparent;
        }
      }
    }
  }
`;

const SpecialSymbolsItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    margin-left: 5px;
  }
`;

const InfoIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
`;

const SymbolTooltip = styled(BaseTooltip)``;

export default PasswordRequirements;
