import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'utils/const';
import api from 'api';
import {showNotification} from 'components/BaseComponents/BaseNotification';

export default function useUserQuery() {
  return useQuery(QUERY_KEYS.USER_DATA, api.wizard.getUserData, {
    staleTime: Infinity,
    suspense: true,
    onError: (error) => {
      console.error('Error fetching user data:');
      console.info(error);

      showNotification('Failed to load data', {type: 'error'});
    },
  });
}
