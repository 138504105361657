import React, {FC, useMemo, useState} from 'react';
import styled from 'styled-components';
import {deleteSNData} from 'api/wizard';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseSeparatorLine from 'components/BaseComponents/BaseSeparatorLine';
import Link from '@mui/material/Link';
import Facebook from 'assets/icons_refactor/Common/facebook.svg';
import LinkedIn from 'assets/icons_refactor/Common/linkedIn.svg';
import Google from 'assets/icons_refactor/Common/google.svg';
import NoAvatar from 'assets/icons_refactor/Header/avatar.svg';
import useConfig from 'hooks/useConfig';
import {font_small} from 'theme/fonts';

interface AuthProps {
  onCancelSN?: () => void;
  redirectParams?: {[key: string]: string};
}

const AuthSocial: FC<AuthProps> = ({onCancelSN, redirectParams}) => {
  const {
    config: {snData},
  } = useConfig();
  const [displaySN, setDisplaySN] = useState<boolean>(true);

  const socialNetworks = [
    {
      name: 'google',
      icon: Google,
    },
    {
      name: 'facebook',
      icon: Facebook,
      path: 'facebook',
    },
    {
      name: 'linkedin',
      icon: LinkedIn,
    },
  ];
  const clearSN = useMemo(
    () => () => {
      onCancelSN && onCancelSN();
      setDisplaySN(false);
    },
    [onCancelSN]
  );
  return (
    <Wrapper data-qa-auto="auth-social">
      {displaySN && snData ? (
        <SocialConnectedContainer>
          <SNIconContainer>
            <ClearSN onClick={() => deleteSNData().then(() => clearSN())}>X</ClearSN>
            {snData.snPicture ? (
              <Avatar src={snData.snPicture} alt="avatar" />
            ) : (
              <NoAvatarIcon icon={NoAvatar} boxW={37} boxH={37} />
            )}
          </SNIconContainer>
          <SocialNetworkBadge network={snData.snName}>
            <SocialIcon
              boxH={30}
              boxW={30}
              icon={snData.snName === 'facebook' ? Facebook : snData.snName === 'linkedin' ? LinkedIn : Google}
            />
          </SocialNetworkBadge>
        </SocialConnectedContainer>
      ) : (
        <SocialWrapper>
          <div data-qa-auto="auth-social-buttons">
            {socialNetworks.map(({name, icon}) => (
              <SNButton
                key={name}
                href={`/auth/redirect/${name}?${new URLSearchParams(redirectParams).toString()}`}
                network={name}
              >
                <SocialIcon icon={icon} />
              </SNButton>
            ))}
          </div>
          <SeparatorBlock>
            <BaseSeparatorLine horizontal />
            OR
            <BaseSeparatorLine horizontal />
          </SeparatorBlock>
        </SocialWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: none;
`;

const SocialWrapper = styled.div`
  width: 100%;

  & div {
    display: flex;
    justify-content: space-between;
  }

  & h6 {
    font-weight: bold;
  }
`;

const SeparatorBlock = styled.div`
  display: grid;
  color: rgb(117, 121, 132);
  grid-template-columns: 167px 26px 167px;
  align-items: center;
  grid-column-gap: 8px;
  margin: 20px 0 20px;
  justify-content: center;

  ${BaseSeparatorLine} {
    color: ${({theme}) => theme.colors.grey030};
    width: 45%;
  }
`;

const ClearSN = styled.span`
  display: none;
  position: absolute;
  align-self: baseline;
  background-color: ${({theme}) => theme.colors.grey050};
  border-radius: 50px;
  border: 1px solid ${({theme}) => theme.colors.grey050};
  ${font_small};
  left: -5px;
  z-index: 1;
  padding: 0 5px;
  cursor: pointer;
  user-select: none;
`;

const SocialNetworkBadge = styled.div<{network: string}>`
  height: 15px;
  margin-left: -15px;
  z-index: 10;
  & svg {
    background-color: ${({theme}) => theme.colors.grey000};
    border-radius: ${({network}) => (['facebook', 'google'].includes(network) ? '10px' : 0)};
  }
`;

// TODO it can be BaseLink component
const SocialButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  width: 30%;
  text-decoration: none;
  color: inherit;
`;

const SocialIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue094};
`;

const SNButton = styled(SocialButton)<{network: string}>`
  ${({
    network,
    theme: {
      colors: {grey000, grey015, blue095},
    },
  }) => `
    background-color: ${grey015};
  
    &:hover {
      background-color: ${network === 'facebook' || network === 'linkedin' ? blue095 : grey000};
      border: 1px solid ${network === 'facebook' || network === 'linkedin' ? blue095 : grey015};
  
      ${SocialIcon} {
        fill: ${grey000};
      }
    }
  `};
`;

const NoAvatarIcon = styled(BaseIcon)`
  width: 52px;
  height: 52px;
`;

const Avatar = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 26px;
`;

const SocialConnectedContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;

  &:hover {
    & ${ClearSN} {
      display: block;
    }
  }
`;

const SNIconContainer = styled.div`
  position: relative;
`;

export default AuthSocial;
