import React, {FC} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import Plus from 'assets/icons_refactor/Client/plus.svg';
import {boldWeight, font_large} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {ADD_MEMBER, DATA_QA_CHAT_ADD_MEMBER} = Consts;

interface AddNewMemberProps {
  onClick: () => void;
}

const AddNewMember: FC<AddNewMemberProps> = ({onClick}) => (
  <Wrapper onClick={onClick} data-qa-auto={DATA_QA_CHAT_ADD_MEMBER}>
    <IconWrapper>
      <Icon icon={Plus} boxH={20} boxW={20} />
    </IconWrapper>
    <Text>{ADD_MEMBER}</Text>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  column-gap: 16px;
  cursor: pointer;
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue100};
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.blue020};
  border-top: 1px solid ${({theme}) => theme.colors.grey030};
`;

const Text = styled.div`
  ${font_large};
  ${boldWeight};
  color: ${({theme}) => theme.colors.grey100};
`;

export default AddNewMember;
