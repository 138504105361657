import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import {Attachment, ChatUser} from 'store/pages/blendTalkStore';
import AttachmentIcon from '../AttachmentIcon';
import Close from 'assets/icons_refactor/Client/close.svg';
import {font_medium} from 'theme/fonts';

interface TextAreaAttachmentItemProps {
  item: Attachment;
  owner?: ChatUser;
  onDelete: (id: string) => void;
  errorIds: string[];
}

const TextAreaAttachmentItem: FC<TextAreaAttachmentItemProps> = ({
  item: {filename, fileId, id},
  onDelete,
  errorIds,
}) => {
  const handleDelete = useCallback(() => {
    !id && !errorIds.includes(fileId) && onDelete(fileId);
    id && errorIds.includes(fileId) && onDelete(fileId);
  }, [id, onDelete, fileId, errorIds]);

  return (
    <Wrapper error={errorIds.includes(id as string) ? 1 : 0}>
      <AttachmentIcon name={filename} />
      {!!id && !errorIds.includes(id as string) && <Loader />}
      <Text title={filename}>{filename}</Text>
      <DeleteIcon icon={Close} onClick={handleDelete} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{error: number}>`
  width: 50%;
  padding: 7px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  column-gap: 4px;
  border: ${({
    theme: {
      colors: {grey030, red100},
    },
    error,
  }) => `1px solid ${error ? red100 : grey030}`};
  background-color: ${({
    theme: {
      colors: {grey000, red020},
    },
    error,
  }) => (error ? red020 : grey000)};
`;

const Text = styled.div`
  ${font_medium};
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 60px);
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 15px;
  left: 9px;
  width: 20px;
  height: 20px;
`;

const DeleteIcon = styled(BaseIcon)`
  cursor: pointer;
  margin-left: auto;
`;

export default TextAreaAttachmentItem;
