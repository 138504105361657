import React, {forwardRef, ForwardedRef, ChangeEvent, FocusEventHandler} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseSimpleInput from 'components/BaseComponents/BaseSimpleInput';
import Search from 'assets/icons_refactor/Client/search.svg';
import Close from 'assets/icons_refactor/Client/close.svg';
import {font_medium} from 'theme/fonts';

interface SearchComponentProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  onClear: () => void;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const SearchInput = forwardRef<HTMLDivElement, SearchComponentProps>(
  ({value, onChange, placeholder, onClear, onFocus}, ref: ForwardedRef<HTMLDivElement>) => (
    <Wrapper ref={ref}>
      <SearchIcon icon={Search} />
      <Input placeholder={placeholder} value={value} onChange={onChange} onFocus={onFocus} />
      {value && <ClearIcon icon={Close} onClick={onClear} boxH={20} boxW={20} />}
    </Wrapper>
  )
);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100vw - 30px);
  }
`;

const SearchIcon = styled(BaseIcon)`
  position: absolute;
  top: 10px;
  left: 13px;
  z-index: 1;
`;

const ClearIcon = styled(BaseIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const Input = styled(BaseSimpleInput)`
  width: inherit;
  border-radius: 8px;
  height: inherit;
  transition: all 1s ease-in;

  .MuiInput-input,
  .MuiInput-input:focus {
    ${font_medium};
    height: inherit;
    padding-left: 42px;
    box-sizing: border-box;
    background-color: ${({theme}) => theme.colors.grey010};
    border: 1px solid ${({theme}) => theme.colors.grey010};
    outline: none;
  }
`;

export default SearchInput;
