import React, {FC, ChangeEvent} from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  indeterminate?: boolean;
}

/** Checkbox color by wrap with &.Mui-checked class:
 *  &.Mui-checked {
 *     color: rgb(0, 128, 0);
 *   }
 */

const BaseCheckbox: FC<Props> = ({disabled, checked, onChange, indeterminate, ...other}) => (
  <Wrapper
    color="primary"
    checked={checked}
    onChange={onChange}
    indeterminate={indeterminate}
    {...other}
    disabled={disabled}
  />
);

const Wrapper = styled(Checkbox)`
  padding: 0;
  color: inherit;

  &:not(.Mui-checked) {
    color: rgb(128, 128, 128);
  }
`;

export default BaseCheckbox;
