import React, {ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router';
import useTracking from 'context/Tracking/useTracking';
import ActionIcon from './ActionIcon';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseSimpleInput from 'components/BaseComponents/BaseSimpleInput';
import Tooltip from './Tooltip';
import SearchInput from './ChatSearch/SearchInput';
import Arrow from 'assets/icons_refactor/Common/small-arrow.svg';
import Checkmark from 'assets/icons_refactor/Client/checkmark.svg';
import Edit from 'assets/icons_refactor/Client/edit.svg';
import {Chat, ChatBodyContent} from 'store/pages/blendTalkStore';
import {showNotification} from 'components/BaseComponents/BaseNotification';
import {useUser} from 'context/UserData';
import useClickOutside from 'hooks/useClickOutside';
import useMobile from 'hooks/useMobile';
import {useStores} from 'hooks/useStores';
import {actionIconByType, isNonWhitespaceOrEmpty, selectMemberActions, selectMemberMdActions} from '../BlendTalkUtils';
import {GTMEvents} from 'utils/globalEvents';
import {boldWeight, font_huge, font_large, font_medium} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  SAVE,
  SEARCH,
  SEARCH_PLACEHOLDER_SELECTED_CHAT,
  USER_TYPE: {TRANSLATOR, CUSTOMER},
  MEMBERS,
  BACK,
  CHAT,
  CHAT_BODY: {CHAT_LIST},
  CHAT_LINK,
  EDIT_TOOLTIP,
  DEFAULT_CHAT_TITLE,
  DEFAULT_CHAT_TITLE_FROM_BE,
  CHAT_LIST_TYPE: {DEFAULT},
  DATA_QA_CHAT_SELECTED_TITLE,
  DATA_QA_CHAT_RENAME_BUTTON,
  EMPTY_TITLE_ERROR,
} = Consts;

interface SelectedChatHeaderProps {
  selectedAction: string;
  onSelectAction: (action: string) => void;
  currentChat: Chat | null;
  onChangeBodyContent: (value: ChatBodyContent) => void;
  search: string;
  onSearch: (value: string) => void;
}

const {
  click_chat_search_icon,
  focus_chat_search_input,
  click_chat_files_icon,
  click_edit_chat_name,
  click_save_chat_name,
} = GTMEvents;

const SelectedChatHeader: FC<SelectedChatHeaderProps> = observer(
  ({currentChat, selectedAction, onSelectAction, onChangeBodyContent, search, onSearch}) => {
    const {isMobile, isTablet} = useMobile();
    const [isEdit, setEdit] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const searchRef = useRef<HTMLDivElement | null>(null);
    const prevSelectedChatId = useRef<null | string>(null);

    const {userData} = useUser();
    const {gtmTrack} = useTracking();
    const {
      blendTalkStore: {updateChatById, chatId, currentUser, token, chatMembers, setChatId, setSearchListInChat, error},
    } = useStores();

    const navigate = useNavigate();

    const isTranslator = useMemo(() => currentUser?.type === TRANSLATOR, [currentUser]);

    useEffect(() => {
      currentChat?.title && setTitle(currentChat.title);
    }, [currentChat?.title]);

    useEffect(() => {
      if (prevSelectedChatId?.current !== currentChat?.chatId) {
        prevSelectedChatId.current = currentChat?.chatId || null;
        setEdit(false);
      }
    }, [currentChat, prevSelectedChatId]);

    const handleEdit = useCallback(() => {
      if (!error.messageList) {
        setEdit(true);
        onSelectAction('');
        userData?.type && gtmTrack({event: click_edit_chat_name, user_role: userData?.type});
      }
    }, [onSelectAction, userData?.type, error.messageList]);

    const handleChangeName = useCallback(({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      setTitle(value);
    }, []);

    const handleSubmit = useCallback(
      (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        if (title.length > 100) return;
        if (!isNonWhitespaceOrEmpty(title)) {
          showNotification(EMPTY_TITLE_ERROR, {type: 'error'});
        } else {
          setEdit(false);
          chatId && title && token && updateChatById(chatId, {title}, token);
          userData?.type && gtmTrack({event: click_save_chat_name, user_role: userData?.type});
        }
      },
      [chatId, title, token, updateChatById, userData?.type]
    );

    const handleClear = useCallback(() => {
      onSearch('');
      setSearchListInChat([]);
    }, [onSearch, setSearchListInChat]);

    const handleActionClick = useCallback(
      (action: string) => (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const selected = action === CHAT && (isMobile || isTablet) ? '' : action;
        setEdit(false);
        onSelectAction(selected);
        action !== SEARCH && handleClear();

        if (userData?.type && (action === 'search' || action === 'files')) {
          const eventName = action === 'search' ? click_chat_search_icon : click_chat_files_icon;
          gtmTrack({event: eventName, user_role: userData?.type});
        }
      },
      [handleClear, isMobile, isTablet, onSelectAction, userData?.type]
    );

    const handleSearchChange = useCallback(
      ({target: {value}}: ChangeEvent<HTMLInputElement>) => onSearch(value),
      [onSearch]
    );

    const handleClickOutsideSearch = useCallback(() => {
      selectedAction === SEARCH && handleClear();
    }, [selectedAction, handleClear]);

    const handleBackClick = useCallback(() => {
      onChangeBodyContent(CHAT_LIST);
      setChatId(null);
      navigate(`${CHAT_LINK}`);
    }, [navigate, onChangeBodyContent, setChatId]);

    useClickOutside(searchRef, handleClickOutsideSearch);

    const chatOptions = useMemo(
      () => (isMobile || isTablet ? selectMemberMdActions : selectMemberActions),
      [isMobile, isTablet]
    );

    const membersCount = useMemo(() => `(${chatMembers.length})`, [chatMembers.length]);

    const handleSearchFocus = useCallback(
      () => gtmTrack({event: focus_chat_search_input, user_role: userData?.type}),
      [userData?.type]
    );

    const isShowEditIcon = useMemo(
      () =>
        (currentUser && currentUser?.type === TRANSLATOR) ||
        (currentUser && currentUser?.type === CUSTOMER && currentChat?.type !== DEFAULT),
      [currentChat, currentUser]
    );

    const ownerName = useMemo(
      () => (currentUser?.type === TRANSLATOR && currentChat?.owner?.name ? `(${currentChat?.owner?.name}) ` : ''),
      [currentUser?.type, currentChat]
    );

    const defaultChatTitle = useMemo(
      () =>
        currentChat?.type === DEFAULT &&
        currentUser?.type === TRANSLATOR &&
        currentChat?.title === DEFAULT_CHAT_TITLE_FROM_BE
          ? DEFAULT_CHAT_TITLE
          : title,
      [currentChat?.type, currentUser, title]
    );

    const chatTitle = useMemo(
      () => (currentUser?.type === TRANSLATOR ? `${ownerName}${defaultChatTitle}` : title),
      [ownerName, currentUser, title, defaultChatTitle]
    );

    return (
      <Wrapper small={!selectedAction || selectedAction === SEARCH ? 0 : 1}>
        <NameContainer error={error.messageList ? 1 : 0}>
          <BackButton onClick={handleBackClick}>
            <PrevArrow icon={Arrow} />
            <PrevText>{BACK}</PrevText>
          </BackButton>
          {isEdit ? (
            <InputContainer onSubmit={handleSubmit}>
              <Input value={title} onChange={handleChangeName} placeholder={''} />
              <SaveButton
                onClick={handleSubmit}
                data-qa-auto={DATA_QA_CHAT_RENAME_BUTTON}
                disabled={title.length > 100}
              >
                <SaveText>{SAVE}</SaveText>
                <CheckIcon icon={Checkmark} boxH={20} boxW={20} />
              </SaveButton>
            </InputContainer>
          ) : (
            <NameWrapper>
              <ChatName title={chatTitle} data-qa-auto={DATA_QA_CHAT_SELECTED_TITLE}>
                {chatTitle}
              </ChatName>
              {isShowEditIcon && (
                <Tooltip title={EDIT_TOOLTIP}>
                  <EditIcon icon={Edit} onClick={handleEdit} />
                </Tooltip>
              )}
            </NameWrapper>
          )}
        </NameContainer>

        <MaxMDSearchContainer>
          <SearchInput
            value={search}
            placeholder={SEARCH_PLACEHOLDER_SELECTED_CHAT}
            onChange={handleSearchChange}
            ref={searchRef}
            onClear={handleClear}
          />
        </MaxMDSearchContainer>

        <Actions isSearch={selectedAction === SEARCH ? 1 : 0}>
          {chatOptions.map(({name, tooltip}) =>
            name === SEARCH && selectedAction === SEARCH ? (
              <MinMdSearchContainer key={name}>
                <SearchInput
                  value={search}
                  placeholder={SEARCH_PLACEHOLDER_SELECTED_CHAT}
                  onChange={handleSearchChange}
                  ref={searchRef}
                  onClear={handleClear}
                  onFocus={handleSearchFocus}
                />
              </MinMdSearchContainer>
            ) : name === MEMBERS && currentUser && isTranslator ? null : (
              <Option
                key={name}
                selected={selectedAction === name || (!selectedAction && name === CHAT) ? 1 : 0}
                onClick={handleActionClick(name)}
              >
                <OptionText>{name}</OptionText>
                {name === MEMBERS && (isMobile || isTablet) && <div>{membersCount}</div>}
                {!isMobile && !isTablet && (
                  <ActionIcon
                    selected={selectedAction === name ? 1 : 0}
                    icon={actionIconByType[name]}
                    tooltipTitle={tooltip}
                  />
                )}
              </Option>
            )
          )}
        </Actions>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{small: number}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  width: ${({small}) => (small ? 'calc(100% - 255px)' : '100%')};
  height: 87px;
  background-color: ${({theme}) => theme.colors.grey000};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey030};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: 151px;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
`;

const NameContainer = styled.div<{error: number}>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 345px;
  opacity: ${({error}) => (error ? 0.5 : 1)};
  cursor: ${({error}) => (error ? 'not-allowed' : 'default')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
    padding: 10px 20px 10px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey030};
    height: 55px;
    max-width: 100%;
  }
`;

const MinMdSearchContainer = styled.div`
  width: 100%;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const BackButton = styled(BaseButton)`
  display: none;
  padding-left: 0;

  &:hover {
    background: none;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
`;

const PrevText = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.grey100};
`;

const InputContainer = styled.form`
  display: flex;
  align-items: center;
  column-gap: 8px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 100%;
  }
`;

const Option = styled.div<{selected: number}>`
  @media ${({theme}) => theme.breakpoints.maxMd} {
    ${font_medium};
    ${boldWeight};
    height: 33px;
    color: ${({theme}) => theme.colors.grey100};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: ${({selected}) => (selected ? 2 : 1)}px;
      background-color: ${({
        selected,
        theme: {
          colors: {grey030, blue080},
        },
      }) => (selected ? blue080 : grey030)};
      position: absolute;
      top: ${({selected}) => (selected ? 31 : 32)}px;
    }
  }
`;

const OptionText = styled.div`
  display: none;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: block;
    text-transform: capitalize;
    text-align: center;
  }
`;

const PrevArrow = styled(BaseIcon)`
  transform: rotate(90deg);
  fill: ${({theme}) => theme.colors.grey100};
  margin: 4px 8px 0 0;
`;

const Input = styled(BaseSimpleInput)`
  ${font_huge};
  ${boldWeight};
  width: 212px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.grey100};

  .MuiInputBase-input .MuiInput-input {
    height: 44px;
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-left: auto;
    width: calc(100% - 100px);
    padding: 0;
  }
`;

const CheckIcon = styled(BaseIcon)`
  display: none;
  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: block;
    fill: ${({theme}) => theme.colors.grey000};
  }
`;

const SaveText = styled.div`
  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const MaxMDSearchContainer = styled.div`
  display: none;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    position: relative;
    display: block;
    width: calc(100% - 16px);
    margin: 0 0 8px 16px;
    height: 38px;
  }
`;

const SaveButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 44px;
  border-radius: 30px;
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};

  &:disabled {
    background-color: ${({theme}) => theme.colors.grey060};
  }

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
      background-color: ${({theme}) => theme.colors.blue100};
      opacity: 0.8;
    }
  }
`;

const EditIcon = styled(BaseIcon)`
  cursor: pointer;
  margin-left: 8px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    margin-left: auto;
  }
`;

const NameWrapper = styled.div`
  max-width: 345px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    max-width: unset;
    flex-shrink: unset;
  }
`;

const ChatName = styled.div`
  ${font_huge};
  ${boldWeight};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    width: calc(100% - 150px);
  }

  @media ${({theme}) => theme.breakpoints.maxSm} {
    width: calc(100vw - 120px);
    margin-left: 10px;
  }
`;

const Actions = styled.div<{isSearch: number}>`
  display: flex;
  align-items: center;
  column-gap: 25px;
  width: ${({isSearch}) => (isSearch ? '100%' : 'auto')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    column-gap: unset;
    width: 100%;
  }
`;

export default SelectedChatHeader;
