import React, {FC, useCallback, useMemo, useState, MouseEvent} from 'react';
import styled, {css} from 'styled-components';
import {useSearchParams} from 'react-router-dom';
import useTracking from 'context/Tracking/useTracking';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import AttachmentIcon from './AttachmentIcon';
import Modal from './Modal';
import {formatBytes, getDateForSortMessage} from '../BlendTalkUtils';
import Download from 'assets/icons_refactor/Client/download.svg';
import TrashBlendTalk from 'assets/icons_refactor/BlendTalk/trash-blend-talk.svg';
import {ChatAttachment, CurrentUser} from 'store/pages/blendTalkStore';
import {useUser} from 'context/UserData';
import {GTMEvents} from 'utils/globalEvents';
import {font_large, font_medium} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {
  REMOVE_ACTION,
  REMOVE_ATTACHMENT_TEXT,
  USER_TYPE: {ADMIN},
  DATA_QA_MESSAGE_ADDED_FILE_ELEMENT,
} = Consts;

const {click_chat_file_download} = GTMEvents;

interface ChatFileProps {
  item: ChatAttachment;
  onClick?: (id: string, page?: number) => void;
  hideDownload?: boolean;
  deleteFile?: (messageId: string, id: string) => void;
  downloadFile?: (id: string, filename: string) => void;
  hideDelete?: boolean;
  currentUser?: CurrentUser | null;
}

const ChatFile: FC<ChatFileProps> = ({
  item: {
    attachment: {filename, size, id, fileId, deletedAt},
    message: {author, pageNumber},
    message,
    chat,
  },
  onClick,
  hideDownload,
  deleteFile,
  downloadFile,
  hideDelete,
  currentUser,
}) => {
  const {userData} = useUser();
  const [open, setOpen] = useState<boolean>(false);
  const fileName = useMemo(() => filename?.slice(0, filename.lastIndexOf('.')), [filename]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {gtmTrack} = useTracking();

  const handleClick = useCallback(() => {
    if (onClick && chat?.chatId) {
      onClick(chat.chatId, pageNumber);
      const searchParamsUrl = new URLSearchParams(searchParams);
      searchParamsUrl.delete('message');
      searchParamsUrl.append('message', message.id);
      setSearchParams(searchParamsUrl.toString());
    }
  }, [chat?.chatId, message.id, onClick, pageNumber]);

  const handleCloseModal = useCallback(() => setOpen(false), []);

  const handleOpenModal = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleSubmitDelete = useCallback(() => {
    !!deleteFile && id && deleteFile(message.id, id);
    handleCloseModal();
  }, [handleCloseModal, deleteFile, id, message.id]);

  const handleDownload = useCallback(() => {
    if (downloadFile) {
      downloadFile(fileId, filename);
      userData?.type && gtmTrack({event: click_chat_file_download, user_role: userData?.type});
    }
  }, [downloadFile, filename, fileId, userData?.type]);

  const createdAt = useMemo(() => message?.createdAt || '', [message]);

  const tooltipTItle = useMemo(
    () => (author?.name ? `${author?.name} | ${getDateForSortMessage(createdAt)}` : getDateForSortMessage(createdAt)),
    [author, createdAt]
  );

  const showDeleteBtn = useMemo(
    () => (currentUser?.id === author?.id && !hideDelete) || (currentUser?.type === ADMIN && !hideDelete),
    [currentUser, author, hideDelete]
  );

  return (
    <Wrapper onClick={handleClick} data-qa-auto={DATA_QA_MESSAGE_ADDED_FILE_ELEMENT}>
      <AttachmentIcon name={filename} deleted={!!deletedAt} />
      <Info>
        <Name title={fileName}>{fileName}</Name>
        <BaseTooltip title={tooltipTItle}>
          <InfoFooter>
            <div>{`${formatBytes(size)} - `}</div>
            <Author>{author?.name}</Author>
            {' | '}
            {getDateForSortMessage(createdAt)}
          </InfoFooter>
        </BaseTooltip>
      </Info>

      {!hideDownload && <DownloadIcon icon={Download} onClick={handleDownload} boxH={16} boxW={16} />}

      {showDeleteBtn && (
        <TrashIconWrapper onClick={handleOpenModal}>
          <TrashIcon icon={TrashBlendTalk} />
        </TrashIconWrapper>
      )}

      <Modal
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitDelete}
        content={<ModalWrapper>{REMOVE_ATTACHMENT_TEXT}</ModalWrapper>}
        actionName={REMOVE_ACTION}
        handleOpen={open}
        title=""
        additionalCloseButton
      />
    </Wrapper>
  );
};

const TrashIconWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 5px;
`;

const TrashIcon = styled(BaseIcon)`
  opacity: 0;
  transition: all 1s ease;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const ModalWrapper = styled.div`
  ${font_large};
  text-align: center;
  max-width: 290px;
  margin: 0 auto 50px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 300ms ease-in;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  min-height: 52px;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey010};
    transition: background-color 300ms ease-in;

    ${TrashIcon} {
      opacity: 1;
      transition: all 1s ease;
    }
  }
`;

const overflowStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Name = styled.div`
  ${font_large};
  max-width: 130px;
  ${overflowStyles};
  color: ${({theme}) => theme.colors.grey100};
`;

const textStyles = css`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};
  ${overflowStyles};
`;

const Info = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
`;

const Author = styled.div`
  ${textStyles};
  max-width: 80px;
`;

const InfoFooter = styled.div`
  display: flex;
  align-items: center;
  ${textStyles};
  column-gap: 3px;
  max-width: 180px;
  ${overflowStyles};
`;

const DownloadIcon = styled(BaseIcon)`
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export default ChatFile;
