import React, {FC} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import Close from 'assets/icons_refactor/Client/close.svg';
import {font_medium} from 'theme/fonts';
import {ChatUser} from 'store/pages/blendTalkStore';
import Consts from '../BlendTalkConsts';

const {DATA_QA_CHAT_POTENTIAL_USERS_TO_ADD_TO_CHAT} = Consts;

interface SelectedGroupUserProps {
  user: ChatUser;
  onDelete: (id: string) => void;
  searchWord: string;
}

const SelectedGroupUser: FC<SelectedGroupUserProps> = ({user: {id, name}, onDelete, searchWord}) => (
  <Wrapper highlighted={searchWord && name.includes(searchWord) ? 1 : 0}>
    <Name data-qa-auto={DATA_QA_CHAT_POTENTIAL_USERS_TO_ADD_TO_CHAT}>{name}</Name>
    <CloseIcon icon={Close} onClick={() => onDelete(id)} />
  </Wrapper>
);

const Wrapper = styled.div<{highlighted: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({
    theme: {
      colors: {blue020, blue050},
    },
    highlighted,
  }) => (highlighted ? blue050 : blue020)};
  border-radius: 27px;
  padding: 0 2px 0 10px;
  column-gap: 4px;
  height: 22px;
  flex-shrink: 0;
`;

const CloseIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue100};
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-top: 2px;
`;

const Name = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.blue100};
`;

export default SelectedGroupUser;
