import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import Grid from '@mui/material/Grid';
import {Rate} from './LowQuality';
import {useStores} from 'hooks/useStores';
import Arrow from 'assets/icons_refactor/Common/weight-arrow.svg';

const StatisticLL = observer(() => {
  const {LmDashboardMainStore} = useStores();
  const {
    statisticLLChart: {loading},
  } = LmDashboardMainStore;

  return (
    <Grid item xs={12} md={6}>
      <Wrapper>
        {loading ? (
          <BaseLoader />
        ) : (
          <Content>
            <Title>Statistic of LLs done:</Title>
            <Rate>
              4 <Icon icon={Arrow} boxH={20} boxW={20} />
            </Rate>
          </Content>
        )}
      </Wrapper>
    </Grid>
  );
});

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey065};
  background-color: ${({theme}) => theme.colors.grey000};
  cursor: pointer;
  height: 100%;
  padding: 10px;
  position: relative;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.green090};
  width: 30px;
  height: 30px;
`;

export default StatisticLL;
