import {makeObservable, observable, action} from 'mobx';
import {CHOICE, CHOICE_ENTRY, UPLOADTYPE, UPLOADTYPE_ENTRY} from '../../pages/Wizard/WizardConsts';
import {ModalMode} from 'components/AuthModal/typesAuthModal';

export function wizardStore() {
  return makeObservable(
    {
      isDrawerOpened: false as boolean,
      isTargetLanguagesMode: false as boolean,
      isOpenLanguageModal: false as boolean,
      selectedMaterialsOption: CHOICE.notSet as CHOICE,
      selectedContentOption: CHOICE_ENTRY.upload as CHOICE_ENTRY,
      uploadType: UPLOADTYPE.file as UPLOADTYPE,
      uploadTypeEntry: UPLOADTYPE_ENTRY.file as UPLOADTYPE_ENTRY,
      isTextAreaVisible: false as boolean,
      activeModalMode: '' as ModalMode,
      setDrawerOpened(isOpened: boolean) {
        this.isDrawerOpened = isOpened;
      },
      manageLanguageModal(isOpen: boolean) {
        if (isOpen) {
          this.isTargetLanguagesMode = false;
          this.isOpenLanguageModal = true;
        } else {
          this.isOpenLanguageModal = false;
        }
      },
      manageTargetLanguageModal() {
        this.isTargetLanguagesMode = true;
        this.isOpenLanguageModal = true;
      },
      setIsTargetLanguagesMode(isTargetMode: boolean) {
        this.isTargetLanguagesMode = isTargetMode;
      },
      setIsOpenLanguageModal(isOpen: boolean) {
        this.isOpenLanguageModal = isOpen;
      },
      setSelectedMaterialsOption(option: CHOICE) {
        this.selectedMaterialsOption = option;
      },
      setUploadType(type: UPLOADTYPE) {
        this.uploadType = type;
      },
      setUploadTypeEntry(type: UPLOADTYPE_ENTRY) {
        this.uploadTypeEntry = type;
      },
      setTextAreaVisible(isVisible: boolean) {
        this.isTextAreaVisible = isVisible;
      },
      setActiveModalMode(mode: ModalMode) {
        this.activeModalMode = mode
      }
    },
    {
      isDrawerOpened: observable,
      isTargetLanguagesMode: observable,
      isOpenLanguageModal: observable,
      selectedMaterialsOption: observable,
      uploadType: observable,
      uploadTypeEntry: observable,
      isTextAreaVisible: observable,
      activeModalMode: observable,
      setDrawerOpened: action.bound,
      manageLanguageModal: action.bound,
      manageTargetLanguageModal: action.bound,
      setIsTargetLanguagesMode: action.bound,
      setIsOpenLanguageModal: action.bound,
      setSelectedMaterialsOption: action.bound,
      setUploadType: action.bound,
      setUploadTypeEntry: action.bound,
      setTextAreaVisible: action.bound,
      setActiveModalMode: action.bound
    }
  );
}
