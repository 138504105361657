import React, { FC , useCallback} from 'react';
import styled from 'styled-components';
import Button, {ButtonProps} from 'react-bootstrap/Button';
import { ModalMode } from '../../AuthModal/typesAuthModal';
import useMobile from 'hooks/useMobile';

type Props = {
  handleOpenModal: (type: ModalMode) => void;
};

const AuthButtons: FC<Props> = ({ handleOpenModal }) => {
  const { isMobile } = useMobile() as { isMobile: boolean }; // Explicitly typing the return value

  const onLogin = useCallback(() => {
    handleOpenModal('login')
  },[handleOpenModal]);

  return (
    <Wrapper>
      <LoginButton onClick={onLogin as any}>
          Sign in
      </LoginButton>

      {!isMobile ? (
        <RegisterButton onClick={() => handleOpenModal('signup')}>
          <span style={{ color: 'white' }}>Register</span>
        </RegisterButton>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding-right: 10px;
`;

const LoginButton = styled(Button)<ButtonProps>`
	background: #5857ff;
	color: ${({ theme }) => theme.colors.grey000};
	border: none;
	font-weight: bold;
	margin-right: 5px;
	white-space: nowrap;
	margin-left: -25px;
`;

const RegisterButton = styled(Button)<ButtonProps>`
	background: #454d55;
	color: ${({ theme }) => theme.colors.grey000};
	border: none;
`;

export default AuthButtons;
