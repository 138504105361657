import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseButton from 'components/BaseComponents/BaseButton';
import {ModalMode} from '../typesAuthModal';
import useConfig from 'hooks/useConfig';
import {useAuth} from 'context/UserData';
import ErrorMessageText from '../Form/ErrorMessageText';
import Arrow from 'assets/icons_refactor/Common/long-arrow.svg';

type Props = {
  mode: ModalMode;
  handleOpenModal: (type: ModalMode) => void;
  email: string | undefined;
  agreeTermsValue: boolean;
  setAgreeTermsValue: (value: boolean) => void;
};

const AuthModalFooter = ({mode, handleOpenModal, email, agreeTermsValue, setAgreeTermsValue}: Props) => {
  const {
    config: {companyName, blendDomainURL},
  } = useConfig();
  const termsOfUseUrl = `https://${blendDomainURL}/terms-and-conditions`;
  const privacyPolicyUrl = `https://${blendDomainURL}/privacy-policy`;

  const {errorMessages, isFetchingAuth} = useAuth();
  const {agree_terms} = errorMessages;

  return (
    <Wrapper>
      {mode === 'signup' ? (
        <>
          <ErrorMessage>
            <ErrorMessageText data-qa-auto="erragree" message={agree_terms && agree_terms[0]} />
          </ErrorMessage>
          <AgreeTerms>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreeTermsValue}
                    name="agreeTerms"
                    color="primary"
                    onChange={({target: {checked}}) => {
                      setAgreeTermsValue(checked);
                    }}
                    inputProps={{'aria-label': 'primary checkbox'}}
                    disabled={isFetchingAuth}
                  />
                }
                label=""
              />
            </FormControl>
            By creating an account, you agree to {companyName}'s
          </AgreeTerms>
          <div>
            <Link href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">
              Terms of Use
            </Link>
            &nbsp;&&nbsp;
            <Link href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
          </div>
        </>
      ) : (
        <Link href={`/auth/resetPassword${email ? '?email=' + email : ''}`} target="__blank" rel="noopener noreferrer">
          Forgot your password?
        </Link>
      )}
      {mode === 'signup' ? (
        <SwitchBox>
          Already have an account?
          <Button data-qa-auto="switch-to-login-mode" onClick={() => handleOpenModal('login')} disableRipple>
            Log in
            <Icon icon={Arrow} boxW={20} boxH={20} />
          </Button>
        </SwitchBox>
      ) : (
        <SwitchBox>
          Don't have an account?
          <Button data-qa-auto="switch-to-signup-mode" onClick={() => handleOpenModal('signup')} disableRipple>
            Sign up
            <Icon icon={Arrow} />
          </Button>
        </SwitchBox>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 0.9rem;
  color: ${({theme}) => theme.colors.green084};
  text-align: center;
  font-weight: 300;

  & a,
  & button {
    padding: 0;
    color: ${({theme}) => theme.colors.blue093};
    vertical-align: baseline;
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.colors.blue093};
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

const Button = styled(BaseButton)`
  margin-left: 5px;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
`;

const AgreeTerms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & label {
    margin: 0;

    & > span {
      padding: 5px;
    }
  }
`;

const SwitchBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.green084};
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue093};
  margin-left: 2px;
`;

export default AuthModalFooter;
