import React from 'react';
import styled from 'styled-components';
import Actions from './Actions';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import useProjectAction from './useProjectAction';
import useUserAction from './useUserAction';
import {useUser} from 'context/UserData';
import Close from 'assets/icons_refactor/Client/close.svg';
import {font_medium, font_huge, boldWeight} from 'theme/fonts';

const AdminActionsView = () => {
  const projectProps = useProjectAction();
  const userProps = useUserAction();
  const {
    adminSubmarine: {isAdminActionsOpen, closeAdminActions},
  } = useUser();

  return isAdminActionsOpen ? (
    <Wrapper>
      <Row>
        <Title>Admin actions</Title>
        <Icon icon={Close} onClick={closeAdminActions} boxH={20} boxW={20} />
      </Row>
      <Actions {...projectProps} {...userProps} />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  ${font_medium};
  z-index: 10;
  min-width: 260px;
  background: ${({theme}) => theme.colors.grey000};
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: grab;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.div`
  ${font_huge};
  ${boldWeight};
  margin: 0;
`;

const Icon = styled(BaseIcon)`
  width: 36px;
  height: 36px;
  fill: ${({theme}) => theme.colors.grey100};
  opacity: 0.7;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export default AdminActionsView;
