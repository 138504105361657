import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import ChatSideBarHeader from './ChatSideBarHeader';
import Arrow from 'assets/icons_refactor/Common/small-arrow.svg';
import {font_small} from 'theme/fonts';
import Consts from '../BlendTalkConsts';

const {HIDE, DATA_QA_CHAT_HIDE_RIGHT_PANEL_BUTTON} = Consts;

interface RightChatPanelProps {
  onClose: () => void;
  children: ReactNode;
  title: string;
  isLoading?: boolean;
  qaTag?: string;
}

const RightChatPanel: FC<RightChatPanelProps> = ({onClose, children, title, isLoading, qaTag}) => (
  <Wrapper data-qa-auto={qaTag}>
    <Header>
      <TopHeaderPart>
        <CloseButton onClick={onClose} data-qa-auto={DATA_QA_CHAT_HIDE_RIGHT_PANEL_BUTTON}>
          {HIDE}
          <ArrowIcon />
        </CloseButton>
      </TopHeaderPart>
      <ChatSideBarHeader text={title} />
    </Header>
    <Body>
      {children}
      {isLoading && <Loader />}
    </Body>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 255px;
  height: calc(100vh - 86px);
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({theme}) => theme.colors.grey000};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.09);
  z-index: 5;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: calc(100vh - 255px);
    top: 150px;
    width: 100%;

    @media screen and (orientation: landscape) {
      height: 100%;
    }
  }
`;

const TopHeaderPart = styled.div`
  width: 100%;
  height: 53px;
  padding: 16px;
  box-sizing: border-box;
`;

const Body = styled.div`
  position: relative;
`;

const Header = styled.div`
  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const CloseButton = styled(BaseButton)`
  ${font_small};
  color: ${({theme}) => theme.colors.grey080};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 20px;

  &:hover {
    background-color: inherit;
  }
`;

const ArrowIcon = styled(Arrow)`
  fill: ${({theme}) => theme.colors.grey080};
  transform: rotate(-90deg);
`;

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

export default RightChatPanel;
