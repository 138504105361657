import React, {FC} from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import Tooltip from './Tooltip';

interface ActionIconProps {
  icon: string;
  selected: number;
  onClick?: () => void;
  tooltipTitle?: string;
}

const ActionIcon: FC<ActionIconProps> = ({icon, selected, onClick, tooltipTitle}) => (
  <Wrapper selected={selected} onClick={onClick}>
    {tooltipTitle ? (
      <Tooltip title={tooltipTitle}>
        <Icon icon={icon} selected={selected} boxH={20} boxW={20} />
      </Tooltip>
    ) : (
      <Icon icon={icon} selected={selected} boxH={20} boxW={20} />
    )}
  </Wrapper>
);

const Icon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    theme: {
      colors: {blue100, grey100},
    },
    selected,
  }) => (selected ? blue100 : grey100)};
  width: 20px;
  height: 20px;
  transition: all 1s ease-in;
`;

const Wrapper = styled.div<{selected: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${({
    theme: {
      colors: {blue020},
    },
    selected,
  }) => (selected ? blue020 : 'transparent')};
  cursor: pointer;
`;

export default ActionIcon;
