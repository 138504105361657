import React from 'react';
import styled from 'styled-components';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import Hint from 'assets/icons_refactor/Client/hint.svg';

export default {
  HELMET_TITLE: 'Professional Online Translation App | BLEND',
  META_CONTENT:
    'BLEND provides professional localization solutions and fast online translation services in more than 120 languages. Let’s get started with BLEND friendly App',
  INSTRUCTIONS_PLACEHOLDER:
    'For example: I want to translate my company’s 5-page website to Spanish. It’s important that the brand vibe stays the same by using casual, everyday Spanish language. Match the format of the original doc.',
  ENTRY_STEP_TITLE: 'What would you like to translate today?',
  LANGUAGE_STEP_TITLE: 'Tell us about your project',
  ENTRY_TABLET_TITLE: "Let's get your translation started! ",
  ENTRY_TABLET_SUBTITLE: 'Before we get to the nitty gritty, tell us a bit about your project.',
  EXPERTISE_SUBTITLE: 'What is your content about?',
  TRANSLATE_FROM: 'Translate from',
  TRANSLATE_TO: 'Translate to',
  ERROR_TRANSLATE_FROM: 'Please select the language you are translating your text from',
  ERROR_TRANSLATE_TO: 'Please select the language(s) you need your content translated to',
  ERROR_EXPERTISE: 'Please choose an expertise',
  EMPTY_LIST_ITEM_CONTENT: 'Choose from the list',
  NOTE_TITLE: 'Names on the document',
  NOTE_CONTENT: (
    <>
      Insert in <b>target language</b> the full names of all relevant persons who are mentioned in the document
    </>
  ),
  EXPERTISE_TOOLTIP:
    'In the process of translation, names can be translated in a number of different ways. To ensure that the translation is authentic and will be approved as such, please include the full names of all the relevant people in the document in target languages',
  EXPERTISE_STEP_TITLE: 'Got it. What’s your project about?',
  INSTRUCTION_SUBTITLE: 'Provide additional instructions',
  BRIEF_SUBTITLE: 'Anything else you want to add (brief doc, inspo, info)?',
  ADD_BRIEF: 'Add Brief',
  CONTENT_STEP_TITLE: 'Time to upload your content',
  MATERIALS_STEP_TITLE: 'Upload your content',
  MATERIALS_STEP_SUBTITLE: 'Are your materials ready to be translated?',
  YES: 'Yes!',
  NOT_YET: 'Not Yet',
  CHOICE_BTN_TOOLTIP: 'You have already uploaded files or pasted text.',
  NO_CHOICE_ERROR_TEXT: 'Please select one of the options',
  UPLOAD_YOUR_FILES: 'Upload your files',
  TYPE_TEXT: 'Type in your text',
  NO_FILE_ERROR_TEXT: 'Please attach a file',
  TEXT_OR_FILE_ERROR_TEXT: 'Please attach a file or type a text',
  UPLOAD_FILE_ERROR: "Please attach a file or type text above. Don't have your content ready?",
  UPLOAD_FILE_ERROR_LINK_TEXT: 'Click here to get a quick quote',
  UPLOAD_FILE_ERROR_LINK: 'https://www.getblend.com/get-quote/',
  TYPE_TEXT_PLACEHOLDER: 'Go ahead and type in or paste your text.',
  TYPE_TEXT_HINT: 'Insert the text you would like to translate',
  URL_EXAMPLE: 'https://example.com',
  WORD: 'word',
  PAGE: 'page',
  COUNT: 'count',
  CREDIT_DESCRIPTION: 'Choose your word count and buy credits that you can apply when your text is ready.',
  ABOUT_SYSTEM: 'About our word count system',
  ABOUT_SYSTEM_TOOLTIP:
    'Our automatic word count may not be 100% accurate...nobody’s perfect, right? We’ll keep you posted if there’s any discrepancies.',
  TYPED_TEXT: 'Typed text',
  WEB_URL: 'Web Url',
  WEB_URL_TITLE: 'Passed your link here',
  PASTE_TEXT_TITLE: 'Type or paste your text here',
  MANUAL_ERROR:
    "Looks like we can't calculate your word count. You can enter it manually or upload a file in text format.",
  NO_EXCEL_OPTION_ERROR_TEXT: 'Oops, you need to select one of the options',
  STEP_BRIEF_TITLE: 'Last step to perfect your project!',
  DOCUMENT_SUBTITLE: 'What are you translating?',
  URL_SUBTITLE: 'Add related URL',
  REFERENCE_SUBTITLE: 'Upload your project brief or reference files',
  WHOLE_DOC: 'Translate whole document',
  WHOLE_DOC_VALUE: 'whole_document',
  INSTRUCTION_DOC: 'Translate according the instructions in a document',
  INSTRUCTION_DOC_VALUE: 'according_the_instructions',
  CALCULATION_ERROR: "Oops, we couldn't calculate your",
  GO_BACK: 'Go Back',
  NEXT: 'Next',
  SMALL: 'small',
  SUBMIT: 'Submit',
  WIZARD_HEADER_TOOLTIP: 'A translator, voice actor, linguistics expert, content creator',
  LOG_IN: 'Log In',
  FREELANCERS: 'Freelancers',
  SUCCESSFUL_PAYMENT_TITLE: 'Awesome, your payment was successful!',
  SUCCESSFUL_PAYMENT_SUBTITLE: 'You\'ll see your payment confirmation after you click "Submit".',
  SUCCESSFUL_PAYMENT_BTN: "Let's complete your order!",
  SUCCESSFUL_PAYMENT_CONTENT:
    'While we match you to a translator, tell us a little more about your project for the best translation.',
  STEP_PLAN_TITLE: 'We’ve got a few great options for you:',
  REVIEW_ORDER: 'Review Order',
  SIDEBAR_TITLE: 'Hey! Let’s get started',
  ALL_RIGHTS_RESERVED: 'All rights reserved',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  MAIN_NOTE: 'Heads up! Your file should only include content intended for translation.',
  PREV_STEP_PROJ_INSTRUCTION: 'Project instructions can be added in the previous step - Project Brief section.',
  NEXT_STEP_PROJ_INSTRUCTION: 'Project instructions can be added to the Project Brief section in the next step.',
  EXCEL_EXT: [
    'application/vnd.ms-excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/x-excel',
    'application/x-dos_ms_excel',
    'application/xls',
    'application/x-xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  WORD_COUNT_ERROR:
    'With this option you cannot adjust the word count. Please select the other option if some text should not be translated',
  BIG_FILE_SIZE_ERROR: 'File exceeds max size 250Mb',
  TALK_TO_SALES: 'Talk to Sales',
  SALES_BTN_GTM_EVENT: 'talk_to_sales_wizard2_click',
  GTM_FILE_UPLOAD_QUOTE_EVENT: 'quote_under_file_upload_click',
  EXPERTISE_CLASSNAME: 'expertise-dropdown',
  FILE_SIZE_ERROR: 'File too big. Please upload a file that is 250MB or smaller.',
  MAX_UPLOAD_MB_SIZE: 250,
};

export const QAConsts = {
  QA_GEO_RESTRICTION: 'user-geo-restriction-modal',
  QA_HEADER_LOGIN_BUTTON: 'header-login-button',
  QA_HEADER_FREELANCERS_BUTTON: 'header-freelancers-button',
  QA_HEADER_TALK_TO_SALES_BUTTON: 'header-talk-tosales-button',
  QA_WORKFLOW_SECTION: 'wizard-workflow-section',
  QA_NO_SOURCE_ERROR: 'no-source-language-chosen-error',
  QA_NO_TARGET_ERROR: 'no-target-language-chosen-error',
  QA_UPLOAD_MATERIAL: 'upload-material',
  QA_YES: 'yes',
  QA_NO: 'no',
  QA_UPLOAD_FILES: 'upload-files',
  QA_UPLOADED_FILE: 'uploaded-file',
  QA_REMOVE_FILE: 'remove-file',
  QA_PASTE_TEXT: 'paste-text',
  QA_NO_CHOICE_ERROR: 'no-option-chosen-error',
  QA_NO_FILE_ERROR: 'no-file-attached-error',
  QA_TEXT_OR_FILE_ERROR: 'no-text-or-file-attached-error',
  QA_NO_EXCEL_OPTION_ERROR: 'no-excel-option-selected-error',
  QA_CLICK_TO_PASTE_TEXT: 'click-to-paste-text',
  QA_TEXTAREA_SECTION: 'textarea-section',
  QA_UPLOADED_RESOURCES: 'uploaded-resources',
  QA_TOTAL_COUNT: 'total-count',
  QA_ESTIMATE_WORD_COUNT: 'estimate-word-count',
  QA_PROJECT_DETAILS: 'project-details',
  QA_UPLOAD_REFERENCE_BTN: 'upload-reference-files-button',
  QA_UPLOAD_REFERENCE: 'uploaded-reference-files',
  QA_STEP_NAV_SECTION: 'step-nav-section',
  QA_STEP_NUMBER: 'step-number',
  QA_NEXT_BUTTON: 'next-button',
  QA_PREV_BUTTON: 'prev-button',
  QA_ADD_BUTTON: 'add-button',
  QA_RESET_BUTTON: 'reset-button',
  QA_SOLUTION_TITLE: 'solutions-title',
  QA_MIN_PAYMENT_INFO: 'minimum-payment-info',
  QA_CHECKOUT_BTN: 'checkout-button',
  QA_WORD_COUNT: 'wordCount',
  QA_ADD_WORD_COUNT: 'add-word-count',
  QA_REMOVE_WORD_COUNT: 'remove-word-count',
  QA_WEB_URL: 'webUrl',
  QA_CONTACT_SALES: 'contact-sales',
  QA_SELECT_PLAN_BTN: 'select-plan-button',
  QA_SELECTED_PLAN_BTN: 'selected-plan-button',
  QA_PRICE_PER_WORD: 'price-per-word',
  QA_ESTIMATION_TIME: 'estimation-time',
  QA_EXPEDITE_ESTIMATION: 'expedite-estimation-time',
  QA_SIDEBAR_TITLE: 'sidebar-title',
  QA_NO_EXPERTISE_CHOSEN: 'no-expertise-chosen-error',
  QA_WEB_URL_TAB: 'web-url-tab',
  QA_BRIEF_WEB_URL: 'brief-web-url',
  QA_BRIEF_ADDED_URL: 'brief-added-url',
  QA_SELECT_EXPERTISE: 'select-expertise',
};

// TODO replace inside default export and remove span + remove react import + remake on symbols
export const Currency: any = {
  USD: <span>&#36;</span>,
  EUR: <span>&#8364;</span>,
  NIS: <span>&#8362;</span>,
  GBP: <span>&#163;</span>,
  CHF: <span>CHF </span>,
  AUD: <span>AUD </span>,
  CAD: <span>CAD </span>,
};

const Icon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  fill: ${({theme}) => theme.colors.grey080};
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.grey100};
`;

const Extra = styled.span`
  color: ${({theme}) => theme.colors.blue100};
`;

const Link = styled.a`
  margin-left: 5px;
`;

export const getExcelRadioOptions = (extra: number | undefined, currency: string | null) => [
  {
    value: 'whole_document',
    label: (
      <Text>
        My entire document can be translated as-is
        <BaseTooltip title="Select this option to translate the entire document. Make sure to exclude any non-translatable text or instructions to avoid an extra charge">
          <Icon icon={Hint} boxW={16} boxH={16} />
        </BaseTooltip>
      </Text>
    ),
  },
  {
    value: 'according_the_instructions',
    label: (
      <Text>
        My document contains content that should not be translated
        {!!extra && (
          <Extra>
            &nbsp;({currency}
            {extra.toFixed(2)} extra)
          </Extra>
        )}
        <BaseTooltip
          title={
            <div>
              Select this option if your files contain additional content that is not intended for translation
              (instructions, hidden text, filtered columns or rows, HTML/VBA code, Dropdown buttons) or if you want to
              receive a bilingual file.
              <Link
                href={'https://help.getblend.com/hc/en-us/articles/10743780065042'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>
            </div>
          }
        >
          <Icon icon={Hint} boxW={16} boxH={16} />
        </BaseTooltip>
      </Text>
    ),
  },
];

export const StepIntroRadioOptions = [
  {
    options: [
      {
        value: 'docs',
        label: 'Files or documents',
      },
    ],
    description: "Upload your files to BLEND's easy-to-use wizard to get started",
  },
  {
    options: [
      {
        value: 'quote',
        label: 'Only need a quote',
      },
    ],
    description: 'Get a quick quote for your translation project',
  },
  {
    options: [
      {
        value: 'windowShopping',
        label: 'Just looking for now',
      },
    ],
    description: 'Take a tour of our platform and check out a demo project',
  },
];

export const STEPS = {
  languages: 0,
  brief: 1,
  materials: 2,
  solutions: 3,
};

export const STEPS_ENTRY = {
  entry: 0,
  languages: 1,
  content: 2,
  solutions: 3,
  payment: 4,
  brief: 5,
};

export const getEntryStep = (isLoggedIn: boolean) => ({
  entry: 0,
  languages: 1,
  content: 2,
  solutions: 3,
  payment: isLoggedIn ? 3 : 4,
  brief: isLoggedIn ? 4 : 5,
});

export enum CHOICE {
  count,
  upload,
  notSet,
}

export enum UPLOADTYPE {
  file,
  paste,
}

export enum CHOICE_ENTRY {
  upload,
}

export enum UPLOADTYPE_ENTRY {
  file,
  url,
  paste,
}

export const DocumentsOptions = [
  {value: 'mobile_apps', label: 'Mobile apps'},
  {value: 'videos', label: 'Videos'},
  {value: 'contracts', label: 'Contracts'},
  {value: 'documents', label: 'Documents'},
  {value: 'website_pages', label: 'Website pages'},
  {value: 'product_descriptions', label: 'Product descriptions'},
  {value: 'ads', label: 'Ads'},
  {value: 'presentations', label: 'Presentations'},
];
