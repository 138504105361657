import React, {FC, MouseEvent, useEffect, useMemo, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseTooltip from 'components/BaseComponents/BaseTooltip';
import MessageReactions from './MessageReactions';
import MessageText from './MessageText';
import ReplyMessage from './ReplyMessage';
import SelectedChatMessageAttachmentList from './SelectedChatMessageAttachmentList';
import More from 'assets/icons_refactor/Common/more-actions.svg';
import Info from 'assets/icons_refactor/Wizard/info-outlined.svg';
import PinFull from 'assets/icons_refactor/BlendTalk/pin-full.svg';
import {ChatMessage, ChatModerationStatus} from 'store/pages/blendTalkStore';
import {getChatMessageBgColor, getChatMessageTextColor, getMessageTime} from '../BlendTalkUtils';
import {boldWeight, font_large, font_medium} from 'theme/fonts';
import {useStores} from 'hooks/useStores';
import Consts from '../BlendTalkConsts';

const {
  STATUS_TOOLTIP,
  DATA_QA_CHAT_MESSAGE,
  DATA_QA_MORE_ACTIONS_ON_MESSAGE,
  DATA_QA_MESSAGE_AUTHOR,
  DATA_QA_MESSAGE_AUTHOR_TYPE,
} = Consts;

interface SelectedMessageContainerProps {
  isSameAuthor?: boolean;
  isAuthor: number;
  onOpenActionPanel: (e: MouseEvent<HTMLDivElement>) => void;
  nextMessageAnotherAuthor: number;
  lastMessage: number;
  showAvatarForAuthor: number;
  showAvatarForAllUsers: number;
  avatar: JSX.Element | null;
  scrolledTo: number;
  authorTypeTitle?: string;
  replyMessage?: ChatMessage;
  onReplyIdSelect: (id: string | null) => void;
  onReactionClick: (reaction: string) => void;
  message: ChatMessage;
  onDownload: (id: string, filename: string) => void;
  selectedMessageId: string | null;
}

const {ON_MODERATION, DECLINED} = ChatModerationStatus;

const SelectedMessageContainer: FC<SelectedMessageContainerProps> = observer(
  ({
    isSameAuthor,
    isAuthor,
    onOpenActionPanel,
    nextMessageAnotherAuthor,
    showAvatarForAuthor,
    showAvatarForAllUsers,
    avatar,
    scrolledTo,
    replyMessage,
    authorTypeTitle,
    onReplyIdSelect,
    onReactionClick,
    lastMessage,
    message: {attachments, createdAt, reactions, author, text, status, pinnedAt, id},
    onDownload,
  }) => {
    const {
      blendTalkStore: {notifications, chatId, readChatById, token, setSelectedMessageId, highlightedMessage},
    } = useStores();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const location = useLocation();

    const selectedMessageUrlId = useMemo(() => {
      const searchParams = new URLSearchParams(location.search);

      return searchParams.get('message');
    }, [location.search]);

    const rootObserverRef = useRef<IntersectionObserver | null>(null);
    const rootRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      rootObserverRef.current = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);

      if (rootRef.current) {
        rootObserverRef.current.observe(rootRef.current);
      }

      return () => {
        rootObserverRef.current?.disconnect();
      };
    }, []);

    const isMessageUnread = useMemo(
      () => chatId && notifications[chatId] && notifications[chatId]?.some((notification) => notification.id === id),
      [chatId, notifications, id]
    );

    useEffect(() => {
      if (isMessageUnread && isVisible && chatId && token) {
        readChatById(chatId, token);
        setIsVisible(false);
      } else if (!isMessageUnread && isVisible) {
        setTimeout(() => setSelectedMessageId(null), 1000);
      }
    }, [isMessageUnread, isVisible, chatId, readChatById, token, setSelectedMessageId]);

    return (
      <Container
        isSameAuthor={isSameAuthor ? 1 : 0}
        author={isAuthor}
        nextMessageAnotherAuthor={nextMessageAnotherAuthor ? 1 : 0}
        lastMessage={lastMessage}
        showAvatarForAuthor={showAvatarForAuthor ? 1 : 0}
        showAvatarForAllUsers={showAvatarForAllUsers ? 1 : 0}
        ref={rootRef}
      >
        {avatar}

        <ReactionWrapper>
          <Content
            author={isAuthor}
            scrolledTo={scrolledTo}
            bgc={getChatMessageBgColor(status, isAuthor)}
            color={getChatMessageTextColor(status, isAuthor)}
            highlighted={selectedMessageUrlId === id || highlightedMessage === id ? 1 : 0}
          >
            {[ON_MODERATION, DECLINED].includes(status) && (
              <InfoWrapper author={isAuthor}>
                <BaseTooltip title={STATUS_TOOLTIP[status as 'onModeration' | 'moderationFailed']} placement="top">
                  <InfoIcon icon={Info} />
                </BaseTooltip>
              </InfoWrapper>
            )}

            <MoreHorizontalContainer onClick={onOpenActionPanel}>
              <MoreHorizontal icon={More} author={isAuthor} data-qa-auto={DATA_QA_MORE_ACTIONS_ON_MESSAGE} />
            </MoreHorizontalContainer>

            <Body data-qa-auto={DATA_QA_CHAT_MESSAGE}>
              <Title
                author={isAuthor}
                hasText={text ? 1 : 0}
                color={getChatMessageTextColor(status, isAuthor)}
                data-qa-auto={DATA_QA_MESSAGE_AUTHOR}
              >
                {!isSameAuthor && (
                  <>
                    {author?.name}
                    <Type
                      color={getChatMessageTextColor(status, isAuthor, 'grey080')}
                      data-qa-auto={DATA_QA_MESSAGE_AUTHOR_TYPE}
                    >
                      {authorTypeTitle}
                    </Type>
                  </>
                )}
              </Title>

              {replyMessage && (
                <ReplyMessage message={replyMessage} isAuthor={isAuthor} onReplyIdSelect={onReplyIdSelect} />
              )}
              {text && (
                <MessageText
                  color={getChatMessageTextColor(status, isAuthor)}
                  message={text}
                  isSameAuthor={isSameAuthor}
                  withPinned={pinnedAt}
                />
              )}
              <SelectedChatMessageAttachmentList
                attachments={attachments}
                isAuthor={isAuthor}
                onDownload={onDownload}
              />
            </Body>
            <Footer color={getChatMessageTextColor(status, isAuthor, 'grey080')}>
              {getMessageTime(createdAt)}
              {pinnedAt && <PinIcon icon={PinFull} author={isAuthor} boxW={20} boxH={20} />}
            </Footer>
          </Content>

          {!!reactions?.length && (
            <MessageReactions reactions={reactions} onReactionClick={onReactionClick} isAuthor={isAuthor} />
          )}
        </ReactionWrapper>
      </Container>
    );
  }
);

const Container = styled.div<{
  isSameAuthor: number;
  author: number;
  nextMessageAnotherAuthor: number;
  lastMessage: number;
  showAvatarForAuthor: number;
  showAvatarForAllUsers: number;
}>`
  max-width: calc(100% - 50px);
  position: relative;
  display: flex;
  column-gap: 16px;
  margin: ${({isSameAuthor, author, showAvatarForAuthor, showAvatarForAllUsers, nextMessageAnotherAuthor}) => {
    if (!isSameAuthor && !author && !showAvatarForAllUsers) {
      return '16px 0 0 54px';
    } else if ((!showAvatarForAuthor && showAvatarForAllUsers) || (author && !nextMessageAnotherAuthor)) {
      return '8px 54px 0 0';
    } else {
      return '8px 0 0 54px';
    }
  }};
  flex-direction: ${({author}) => (author ? 'row-reverse' : 'row')};

  @media ${({theme}) => theme.breakpoints.maxMd} {
    max-width: 100%;
    margin: ${({isSameAuthor, author, showAvatarForAllUsers, showAvatarForAuthor}) => {
      if (author) {
        return '8px 0 0 0';
      } else if (!isSameAuthor && !author && !showAvatarForAllUsers) {
        return '16px 0 0 54px';
      } else if (!showAvatarForAuthor && showAvatarForAllUsers) {
        return '8px 0 0';
      } else {
        return '8px 0 0 54px';
      }
    }};
  }
`;

const MoreHorizontal = styled(BaseIcon)<{author: number}>`
  transform: rotate(90deg);
  fill: ${({
    theme: {
      colors: {grey000, grey100},
    },
    author,
  }) => (author ? grey000 : grey100)};
  cursor: pointer;
`;

const MoreHorizontalContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
`;

const ReactionWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  row-gap: 4px;

  &:hover {
    ${MoreHorizontalContainer} {
      display: flex;
    }
  }
`;

const Content = styled.div<{
  author: number;
  scrolledTo: number;
  bgc: string;
  highlighted: number;
}>`
  max-width: 100%;
  padding: ${({scrolledTo}) => (scrolledTo ? '8px 38px 8px 8px' : '10px 40px 10px 10px')};
  box-sizing: border-box;
  background-color: ${({theme, bgc, highlighted}) => (highlighted ? theme.colors.cyan100 : theme.colors[bgc])};
  border-radius: ${({author}) => (author ? '10px 10px 0 10px' : '0 10px 10px')};
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: ${({scrolledTo}) => (scrolledTo ? '0 4px 14px rgba(0, 0, 0, 0.3)' : 'none')};
  border: ${({scrolledTo, theme}) => (scrolledTo ? `2px solid ${theme.colors.grey090}` : 'none')};
  transition: border 0.3s ease-in-out;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    max-width: calc(100vw - 95px);
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Footer = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  column-gap: 2px;
  ${font_medium};
  color: ${({theme, color}) => theme.colors[color]};
  width: 42px;
  margin-top: auto;
  text-align: right;
`;

const userStyles = css`
  ${font_large};
  ${boldWeight};
`;

const Title = styled.div<{author: number; hasText: number; color: string}>`
  ${userStyles};
  color: ${({theme, color}) => theme.colors[color]};
  display: flex;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  margin-bottom: ${({hasText}) => (hasText ? '0' : '8px')};
`;

const Type = styled.div<{color: string}>`
  ${userStyles};
  color: ${({theme, color}) => theme.colors[color]};
`;

const InfoIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.green085};
  width: 16px;
  height: 16px;
`;

const InfoWrapper = styled.div<{author: number}>`
  position: absolute;
  top: 6px;
  left: ${({author}) => (author ? '-30px' : 'unset')};
  right: ${({author}) => (!author ? '-30px' : 'unset')};
`;

const PinIcon = styled(BaseIcon)<{author: number}>`
  width: 15px;
  height: 15px;
  fill: ${({
    author,
    theme: {
      colors: {grey100, grey000},
    },
  }) => (author ? grey000 : grey100)};
`;

export default SelectedMessageContainer;
