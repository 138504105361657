import React, {FC, useCallback} from 'react';
import styled, {css} from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BlendTalk from 'assets/icons_refactor/SideMenu/side-chat.svg';
import {useStores} from 'hooks/useStores';
import {font_xsmall} from 'theme/fonts';

interface BlendTalkIconProps {
  selected?: boolean;
  navigation?: boolean;
}

const BlendTalkIcon: FC<BlendTalkIconProps> = observer(({selected, navigation}) => {
  const {
    blendTalkStore: {notifications},
  } = useStores();

  const navigate = useNavigate();

  const handleClick = useCallback(() => navigation && navigate('/blend-talk/chat/'), [navigation]);

  const totalCount = Object.values(notifications).reduce(
    (total, notificationsArray) => total + notificationsArray.length,
    0
  );

  return (
    <Wrapper selected={selected ? 1 : 0} onClick={handleClick}>
      <Icon icon={BlendTalk} boxH={20} boxW={20} selected={selected ? 1 : 0} />
      {!!totalCount && <Counter>{totalCount >= 100 ? '99+' : totalCount}</Counter>}
    </Wrapper>
  );
});

const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div<{selected: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({
    selected,
    theme: {
      colors: {blue020, grey000},
    },
  }) => (selected ? blue020 : grey000)};
`;

const Counter = styled.div`
  ${font_xsmall};
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  position: absolute;
  top: 3px;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${overflowStyles};
`;

const Icon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    selected,
    theme: {
      colors: {grey090, blue100},
    },
  }) => (selected ? blue100 : grey090)};
`;

export default BlendTalkIcon;
